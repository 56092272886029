import { Button, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getPrefixedRoute } from 'services/theming';
import BtnIcon from 'components/BtnIcon';
import { ExpandableTableRow, FontType, Row, RowDataType, TableCellType } from 'generated/graphql';

const stickyColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 1,
  boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
};

const PriceTableRowDetail = ({
  data,
  setShowGraphs,
}: {
  data: ExpandableTableRow;
  setShowGraphs(showGraph: boolean): void;
}) => {
  const navigate = useNavigate();
  if (!data || data.data?.__typename !== RowDataType.Rows) return null;
  const { itemRows: rows }: { itemRows: Row[] } = data.data;

  const handleLink = (url: string) => {
    const target = getPrefixedRoute(url) as string;
    if (target.startsWith('http')) {
      window.location.href = target;
    } else {
      navigate(target.replace('#', ''), { replace: true });
    }
  };

  return (
    <>
      {rows?.map((row, i) => (
        <TableRow
          key={i}
          sx={{
            borderTopStyle: 'hidden',
            breakInside: 'avoid',
          }}
        >
          {row?.cells.map((cellData, j) => (
            <TableCell
              key={j}
              align={cellData?.align.toLowerCase() as 'left' | 'right'}
              sx={(theme) => ({
                fontFamily: theme.typography.fontFamily,
                backgroundColor: theme.palette.background.paper,
                fontWeight: cellData.fontStyle,
                '&.MuiTableCell-root': {
                  borderRight: j > 0 ? 'hidden' : '',
                  borderLeft: j === 0 ? 'hidden' : '',
                  paddingLeft: j === 0 ? theme.spacing(2) : '',
                  textAlign: cellData.align,
                  fontFamily: cellData.fontType === FontType.Mono ? 'Roboto Mono' : '',
                  ...(j === 0 && stickyColumnStyle),
                },
              })}
            >
              {cellData.type === TableCellType.Action ? (
                <Button
                  size="small"
                  variant={cellData.buttons[0].variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
                  color={cellData.buttons[0].color.toLowerCase() as 'primary' | 'secondary'}
                  startIcon={<BtnIcon icon={cellData.buttons[0].icon} />}
                  onClick={(event) => {
                    event.stopPropagation();
                    const url = cellData.buttons[0].actions[0]?.url ?? '';
                    if (url.length > 0) {
                      handleLink(url);
                    }
                    if (setShowGraphs) setShowGraphs(true);
                  }}
                >
                  {cellData.buttons[0].value || cellData.buttons[0].actions[0].value}
                </Button>
              ) : (
                <>{cellData?.value}</>
              )}
            </TableCell>
          ))}
          <TableCell // Adds additional space after table columns for pricing dashboard sales table
            sx={(theme) => ({
              maxWidth: theme.spacing(1),
              minWidth: theme.spacing(1),
              '&.MuiTableCell-root': {
                borderRight: 'hidden',
              },
            })}
          />
        </TableRow>
      ))}
    </>
  );
};

export default PriceTableRowDetail;
