import { AnalyticsCallOptions } from 'firebase/analytics';
import { useCallback } from 'react';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { logAnalytics } from 'utils/logAnalytics';
import useUser from './useUser';

enum Platform {
  FRESH = 'fresh',
  INPUTS = 'inputs',
  NILE = 'nile',
  VILLA = 'villa',
  FRESHMARK = 'freshmark',
  OTHER = 'other',
}

const getPlatform = (isNileFresh: boolean, isInputs: boolean, isVilla: boolean, isFreshmark: boolean): Platform => {
  if (isNileFresh) return Platform.FRESH;
  if (isInputs) return Platform.INPUTS;
  if (isVilla) return Platform.VILLA;
  if (isFreshmark) return Platform.FRESHMARK;
  return Platform.OTHER;
};

enum UserType {
  BUYER = 'buyer',
  SELLER = 'seller',
  ADMIN = 'admin',
  NILE = 'nile',
  OTHER = 'other',
}

const getUserType = (isAdmin: boolean, isSeller: boolean, isBuyer: boolean, isNileUser: boolean): string => {
  let user = '';
  if (isAdmin) user = UserType.ADMIN;
  if (isSeller) user = UserType.SELLER;
  if (isBuyer) user = UserType.BUYER;
  if (isNileUser) user = user.length > 0 ? `${user}_${UserType.NILE}` : UserType.NILE;

  return UserType.OTHER;
};

const buildGroupID = (platform: Platform, userType: string) => {
  return `${platform}_${userType}`;
};

export const useAnalytics = () => {
  const { isNileFreshProfile, isInputsProfile, isVillaProfile, isFreshmarkProfile } = useWhiteLabelling();
  const { isAdmin, isBuyer, isSeller, isNileUser } = useUser();

  const platform = getPlatform(isNileFreshProfile, isInputsProfile, isVillaProfile, isFreshmarkProfile);
  const userType = getUserType(isAdmin, isSeller, isBuyer, isNileUser);
  const groupId = buildGroupID(platform, userType);

  const handleLogAnalytics = useCallback(
    async (
      eventName: string,
      eventParams?: { [key: string]: any; search_term?: string | undefined },
      options?: AnalyticsCallOptions | undefined,
    ) => {
      logAnalytics(eventName, eventParams, options, groupId);
    },
    [groupId],
  );

  return { logAnalytics: handleLogAnalytics };
};
