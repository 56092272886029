import { Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { getPrefixedRoute } from 'services/theming';
import Button from 'components/Button';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { Card } from 'generated/graphql';
import BtnIcon from './BtnIcon';
import { FormDialogState } from './FormDialog';
import LazyFormDialog from './LazyFormDialog';
import { defaultFormDialogState } from './default-form-dialog-state';

const ActionAlertCard = ({ content, onRefetch = () => {} }: { content: Card; onRefetch?: () => void }) => {
  const { isEnabled } = useFeatures();
  const isChepAutomationEnabled = isEnabled('ChepAutomation');

  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefetch);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);

  if (!content || !isChepAutomationEnabled) return null;

  const hasButton = content.button && content.button.value;

  return (
    <>
      <Alert
        severity="info"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: isChepAutomationEnabled ? theme.spacing(0.5, 1) : theme.spacing(0.5, 1.5),
          '& .MuiAlert-message': {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.common.white,
          },
        })}
        icon={<BtnIcon icon={content.icon} />}
      >
        {
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              [theme.breakpoints.down('md')]: {
                gap: 0.75,
              },
            })}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: '1rem',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '0.8rem',
                },
              })}
            >
              <strong>{content.title}</strong>
              {content.description}
            </Typography>
            {hasButton && (
              <Button
                variant={
                  content.button.variant
                    ? (content.button.variant?.toLowerCase() as 'text' | 'outlined' | 'contained')
                    : 'outlined'
                }
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    paddingX: 0,
                  },
                })}
                color={
                  content.button.color ? (content.button.color?.toLowerCase() as 'primary' | 'secondary') : 'primary'
                }
                href={content.button.actions[0]?.url ? getPrefixedRoute(content.button.actions[0].url) : ''}
                onClick={() => {
                  const btnAction = content.button.actions[0];
                  handleSchemeOrQuery(btnAction.url, btnAction.query, { setFormDialog: setFormDialog });
                }}
              >
                {content.button.value}
              </Button>
            )}
          </Stack>
        }
      </Alert>
      {formDialog.open && (
        <LazyFormDialog
          type={formDialog.type}
          editIDs={formDialog.editIDs}
          open={formDialog.open}
          onClose={() => {
            setFormDialog(defaultFormDialogState);
          }}
          onSubmit={() => {
            setFormDialog(defaultFormDialogState);
            onRefetch();
          }}
        />
      )}
    </>
  );
};

export default ActionAlertCard;
