import { Box, Stack } from '@mui/material';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import ProductIcon from 'components/ProductIcon/ProductIcon';
import { QualityChipSize, QualityChipVariant, QualityChips } from 'components/QualityChip';
import { baseTheme } from 'config/base-theme';
import useImageThumbnail from 'hooks/useImageThumbnail';
import { ButtonColor, MarketImage } from 'generated/graphql';

const DEFAULT_MARKET_IMAGE_SIZE = 64;

export const BuyersMarketImage = ({
  marketImage,
  sku = '',
  size = DEFAULT_MARKET_IMAGE_SIZE,
  width,
  height,
  noClip = false,
  thumbnail,
  qualityChipVariant = QualityChipVariant.BadgeNoLabel,
  showCaption = false,
}: {
  marketImage?: MarketImage;
  sku?: string;
  size?: number;
  width?: string;
  height?: string;
  noClip?: boolean;
  thumbnail?: string;
  qualityChipVariant?: QualityChipVariant;
  showCaption?: boolean;
}) => {
  const thumbnailSRC = useImageThumbnail(marketImage?.image, thumbnail);
  const src = thumbnail ? thumbnailSRC : marketImage?.image;
  const captions = marketImage?.captions.map((caption) => {
    return {
      color: marketImage?.badgeColor ?? ButtonColor.Info,
      value: caption,
    };
  });
  const hasCaptions = captions && marketImage?.captions && marketImage.captions.length > 0;

  return (
    <Stack gap={0.5} sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', alignSelf: 'center' }}>
        {marketImage?.badgeColor && (
          <QualityChips
            chips={[
              {
                color: marketImage?.badgeColor, // No QualityChip.value since it won't be used
                value: marketImage?.badgeLabel,
              },
            ]}
            variant={qualityChipVariant}
          />
        )}
        {src && src?.length > 0 ? (
          <Box
            component="img"
            src={src}
            alt="Product icon"
            sx={(theme) => ({
              width: width ? width : size,
              height: height ? height : size,
              objectFit: noClip ? 'contain' : 'cover',
              objectPosition: 'center',
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.grey[200],
            })}
          />
        ) : (
          <Box
            sx={{
              minWidth: width ? width : size,
              minHeight: height ? height : size,
              alignContent: 'center',
            }}
          >
            <Stack
              sx={{
                minWidth: width ? width : size,
                minHeight: height ? height : size,
              }}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {sku.length > 0 ? (
                <ProductIcon sku={sku} size={40} />
              ) : (
                <MdOutlineImageNotSupported
                  size={40}
                  style={{
                    color: baseTheme.palette.grey[400],
                  }}
                />
              )}
            </Stack>
          </Box>
        )}
      </Box>
      {showCaption && hasCaptions && (
        <QualityChips chips={captions} variant={QualityChipVariant.Simple} size={QualityChipSize.Small} />
      )}
    </Stack>
  );
};
