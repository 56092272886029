import { TextField, Box, SxProps, useTheme } from '@mui/material';
import { useState, useEffect, useRef, ReactNode } from 'react';
import dayjs from 'services/dayjs';
import { BaseTheme, baseTheme } from 'config/base-theme';
import useDetectDeviceType from 'hooks/useDetectDeviceType';
import useIsViewport from 'hooks/useIsViewport';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const getDisplayDateValue = (date: string) => {
  const today = dayjs().format(DEFAULT_DATE_FORMAT).toString();
  const yesterday = dayjs().subtract(1, 'days').format(DEFAULT_DATE_FORMAT).toString();
  const tomorrow = dayjs().add(1, 'days').format(DEFAULT_DATE_FORMAT).toString();

  switch (date) {
    case today:
      return 'Today';
    case yesterday:
      return 'Yesterday';
    case tomorrow:
      return 'Tomorrow';
  }
  return date;
};

const MuiStyle = (theme: BaseTheme) => ({
  backgroundColor: theme.palette.common.white,
  paddingX: theme.spacing(2),
  paddingY: theme.spacing(1),
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightLight,
});

const DefaultStyle = (theme: BaseTheme) => ({
  backgroundColor: 'transparent',
  paddingX: 0,
  paddingY: 0,
  fontSize: 'large',
  fontWeight: theme.typography.fontWeightRegular,
});

interface CustomDatePickerProps {
  startDate?: dayjs.Dayjs;
  color?: string;
  label?: string;
  variant?: 'default' | 'mui';
  fieldSxProp?: SxProps<BaseTheme>;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  isLanguageDate?: boolean;
  min?: dayjs.Dayjs;
  max?: dayjs.Dayjs;
  onDatePickerChange?: (date: dayjs.Dayjs) => void;
}

export const CustomDatePicker = ({
  startDate,
  variant = 'default',
  color = baseTheme.palette.common.black,
  label = '',
  fieldSxProp,
  endAdornment,
  startAdornment,
  isLanguageDate = false,
  min,
  max,
  onDatePickerChange,
}: CustomDatePickerProps) => {
  const { isIOSMobile } = useDetectDeviceType();

  const [date, setDate] = useState(dayjs(startDate).format(DEFAULT_DATE_FORMAT)); // For date picker heading
  const [displayDate, setDisplayDate] = useState(isLanguageDate ? getDisplayDateValue(date) : date);
  const isMobile = useIsViewport('sm');

  useEffect(() => {
    const newDate = dayjs(startDate).format(DEFAULT_DATE_FORMAT);
    setDate(newDate);
    setDisplayDate(isLanguageDate ? getDisplayDateValue(newDate) : newDate);
  }, [startDate, isLanguageDate]);

  const handleSelectDate = (val: dayjs.Dayjs) => {
    const timezoneOffset = val.utcOffset();
    const offsetStartDate = val.add(timezoneOffset, 'minute');
    if (onDatePickerChange) onDatePickerChange(offsetStartDate);
  };

  const fieldRef = useRef<HTMLInputElement>();
  const hasShowPicker = !!fieldRef.current?.showPicker; //Some versions of Safari do no support showPicker, in this case we will keep it visible

  const theme = useTheme();
  const style = variant === 'mui' ? MuiStyle(theme) : DefaultStyle(theme);

  return (
    <Box
      sx={{
        minWidth: 'fit-content',
        display: 'flex',
      }}
    >
      <TextField
        id="startDate"
        type="date"
        slotProps={{
          input: { disableUnderline: true },
          htmlInput: { min: min?.format('YYYY-MM-DD'), max: max?.format('YYYY-MM-DD') },
        }}
        value={date}
        onChange={(e) => {
          if (e.target.value.length > 0) {
            setDate(dayjs(e.target.value).format(DEFAULT_DATE_FORMAT));
            handleSelectDate(dayjs(e.target.value));
          }
        }}
        sx={
          isIOSMobile
            ? {
                paddingTop: 1,
                '& input': {
                  color: color,
                  backgroundColor: style.backgroundColor,
                  '&::-webkit-datetime-edit': {
                    width: '150px',
                  },
                },
              }
            : {
                visibility: hasShowPicker ? 'hidden' : 'visible', // Hide only if `showPicker` is supported
                position: 'absolute',
                '& input': {
                  color: hasShowPicker ? 'transparent' : color, // Ensure text is visible on iOS
                  backgroundColor: hasShowPicker ? 'transparent' : style.backgroundColor,
                  '&::-webkit-datetime-edit': {
                    width: '150px',
                  },
                },
                '& input::-webkit-datetime-edit-year-field:focus, & input::-webkit-datetime-edit-month-field:focus, & input::-webkit-datetime-edit-day-field:focus':
                  {
                    backgroundColor: hasShowPicker ? 'transparent' : style.backgroundColor,
                  },
                '& input::-webkit-calendar-picker-indicator': {
                  background: 'transparent',
                  bottom: 0,
                  color: 'transparent',
                  cursor: 'pointer',
                  height: 'auto',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: 'auto',
                },
              }
        }
        inputRef={fieldRef}
      />
      {!isIOSMobile && (
        <TextField
          label={label}
          sx={(theme) => ({
            pointerEvents: hasShowPicker ? 'all' : 'none',
            ...(variant === 'mui' && {
              '& > div': {
                backgroundColor: style.backgroundColor,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.spacing(0.5),
              },
              '& label': {
                position: 'absolute',
                top: theme.spacing(1.375),
                left: theme.spacing(1.5),
                zIndex: 10,
                paddingX: theme.spacing(1),
                fontSize: '1rem',
                background: `linear-gradient(to bottom, ${'transparent'} 38%, ${theme.palette.common.white} 38%)`,
              },
            }),
            '& input': {
              width: variant === 'mui' ? (isMobile ? '100px' : '154px') : '110px', // 154px = calendar modal width
              color: color,
              fontSize: style.fontSize,
              fontWeight: style.fontWeight,
              backgroundColor: style.backgroundColor,
              paddingX: style.paddingX,
              paddingY: style.paddingY,
            },
            ...(typeof fieldSxProp === 'function' ? fieldSxProp(theme) : {}),
          })}
          value={displayDate}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            fieldRef.current?.showPicker();
          }}
          slotProps={{
            input: {
              disableUnderline: true,
              readOnly: true,
              endAdornment: endAdornment,
              startAdornment: startAdornment,
            },
          }}
        ></TextField>
      )}
    </Box>
  );
};

export default CustomDatePicker;
