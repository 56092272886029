import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { MdRefresh } from 'react-icons/md';
import { getThemeVariant, ThemeVariant } from 'services/theming';
import { baseTheme } from 'config/base-theme';
import useLogSentryWithContext from 'hooks/useLogSentryWithContext';
import Button from './Button';
import Logo from './logo-color.svg?react';
import LogoFreshmark from './logo-freshmark.svg?react';
import LogoVilla from './logo-villa.svg?react';

const DEFAULT_MESSAGE = 'Something went wrong, if the issue persists please contact us.';

const logoStyle = {
  maxHeight: 100,
  maxWidth: 130,
};

const NetworkError = ({
  message = DEFAULT_MESSAGE,
  error,
  componentStack,
}: {
  message?: string;
  error?: Error | null;
  componentStack?: string;
}) => {
  const themeVariant = getThemeVariant();
  const logError = useLogSentryWithContext();

  const handleRefresh = () => window.location.reload();

  useEffect(() => {
    if (error) {
      logError(error);

      if (componentStack) {
        logError(`Component stack: ${componentStack}`);
      }
    }
  }, [error, componentStack, logError]);

  return (
    <Box
      style={{
        width: '100%',
        textAlign: 'center',
        marginTop: baseTheme.spacing(8),
      }}
    >
      {[ThemeVariant.Nile, ThemeVariant.Inputs].includes(themeVariant) && <Logo style={logoStyle} />}
      {themeVariant === ThemeVariant.FreshMark && <LogoFreshmark style={logoStyle} />}
      {themeVariant === ThemeVariant.Villa && <LogoVilla style={logoStyle} />}

      <Typography sx={{ my: 2 }} variant="h6" color="error">
        {message}
      </Typography>

      <Button onClick={handleRefresh} startIcon={<MdRefresh size={20} />} variant="contained">
        Refresh
      </Button>
    </Box>
  );
};

export default NetworkError;
