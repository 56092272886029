import { ButtonProps, CircularProgress } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'hooks/useSnackBar';
import { Button as ButtonData, CompleteAction, CompleteType } from 'generated/graphql';
import Button from './Button';

interface DynamicButtonProps extends ButtonProps {
  button: ButtonData;
  identifier?: string;
  eTag?: string;
  fetching?: boolean;
  reset?: boolean;
  isLoading?: boolean;
  onHide?: (hide: boolean) => void;
  onDisable?: (disable: boolean) => void;
  onReset?: () => void;
}

const MAX_REFRESH_TIME = 30000;

const getCompositeKey = (eTag?: string) => {
  if (!eTag) return '';
  const [entityType, id, hash] = eTag.split('.');
  return `${entityType}.${id}.${hash}`;
};

const DynamicButton: React.FC<DynamicButtonProps> = ({
  button,
  identifier = 'default',
  eTag,
  onClick,
  onHide,
  onDisable,
  disabled,
  size,
  fetching,
  reset,
  onReset,
  children,
  isLoading,
  ...rest
}) => {
  const [label, setLabel] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>();
  const { showSnackbar, hideSnackbar } = useSnackbar();

  const handleDisable = useCallback(
    (disable: boolean) => {
      setIsDisabled(disable);
      onDisable?.(disable);
    },
    [onDisable],
  );

  const resetButtonStates = useCallback(() => {
    setLabel(undefined);
    hideSnackbar();
    handleDisable(false);
    onHide?.(false);
  }, [handleDisable, hideSnackbar, onHide]);

  useEffect(() => {
    if (reset) {
      resetButtonStates();
      localStorage.removeItem(identifier);
      onReset?.();
    }
  }, [reset, resetButtonStates, onReset, identifier]);

  const removeETagFromStorage = useCallback(() => {
    if (!identifier) return;
    localStorage.removeItem(identifier);
    resetButtonStates();
  }, [identifier, resetButtonStates]);

  const clearStorageAfterTTL = useCallback(
    (ttl: number) => {
      const timer = setTimeout(() => {
        removeETagFromStorage();
      }, ttl);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    },
    [removeETagFromStorage],
  );

  const handleCompleteTypeAction = useCallback(
    (onComplete: CompleteAction) => {
      switch (onComplete.type) {
        case CompleteType.Hide:
          onHide?.(true);
          handleDisable(true);
          break;
        case CompleteType.PreviewNext:
          setLabel(onComplete.nextLabel);
          handleDisable(true);
          break;
        case CompleteType.Ignore:
          handleDisable(false);
          return;
        default:
          return;
      }

      showSnackbar({ message: `${children?.toString()} request confirmed` });
      clearStorageAfterTTL(MAX_REFRESH_TIME);
    },
    [handleDisable, onHide, showSnackbar, clearStorageAfterTTL, children],
  );

  const retrieveStoredValues = useCallback((): { eTag: string; onComplete: CompleteAction; time: number } | null => {
    const storedValue = localStorage.getItem(identifier);
    return storedValue ? JSON.parse(storedValue) : null;
  }, [identifier]);

  const validateETagOnClick = useCallback(() => {
    if (!identifier) return;
    const storedValue = retrieveStoredValues();

    if (!storedValue?.eTag && eTag) {
      localStorage.setItem(
        identifier,
        JSON.stringify({ eTag: eTag, onComplete: button.actions[0].onComplete, time: Date.now() }),
      );
    } else if (storedValue?.eTag && getCompositeKey(storedValue.eTag) !== getCompositeKey(eTag)) {
      removeETagFromStorage();
      return;
    }
  }, [eTag, identifier, button.actions, removeETagFromStorage, retrieveStoredValues]);

  const validateETagOnLoad = useCallback(() => {
    if (!identifier) return;

    const storedValue = retrieveStoredValues();

    if (!storedValue) {
      return;
    }

    if (storedValue?.time && Date.now() - MAX_REFRESH_TIME > storedValue?.time) {
      removeETagFromStorage();
    } else if (getCompositeKey(storedValue.eTag) === getCompositeKey(eTag)) {
      if (!storedValue.onComplete) {
        return;
      }
      handleCompleteTypeAction(storedValue.onComplete);
    } else {
      removeETagFromStorage();
    }
  }, [eTag, identifier, handleCompleteTypeAction, removeETagFromStorage, retrieveStoredValues]);

  useEffect(() => {
    validateETagOnLoad();
  }, [validateETagOnLoad]);

  useEffect(() => {
    if (!fetching) {
      validateETagOnLoad();
    }
  }, [fetching, validateETagOnLoad]);

  const handleClick = async (event: any) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }

    if (button.actions.length !== 1 || !button.actions[0].onComplete?.type) {
      return;
    }

    const action = button.actions[0];
    if (!action.onComplete) return;

    handleCompleteTypeAction(action.onComplete);
    validateETagOnClick();
  };

  return (
    <div>
      <Button
        color={button.color.toLowerCase() as DynamicButtonProps['color']}
        variant={button.variant.toLowerCase() as DynamicButtonProps['variant']}
        size={size ?? 'small'}
        onClick={handleClick}
        disabled={isDisabled || disabled}
        {...rest}
      >
        {isLoading && (
          <CircularProgress
            size={12}
            color={button.color.toLowerCase() as 'primary' | 'secondary'}
            sx={{ marginRight: 1 }}
          />
        )}
        {label ?? children}
      </Button>
    </div>
  );
};

export default DynamicButton;
