import { SnackbarProps } from '@mui/material';
import { devtoolsExchange } from '@urql/devtools';
import { persistedExchange } from '@urql/exchange-persisted';
import { SubscribePayload } from 'graphql-ws';
import createAuthExchange, { createWebSocketClient } from 'services/auth';
import { createClient, fetchExchange, cacheExchange as defaultCacheExchange, subscriptionExchange } from 'urql';
import { SnackbarVariant } from 'hooks/useSnackBar';
import { storeTraceID } from 'utils/traceid';

// Create the URQL client
export const makeClient = (showSnackbar?: (props: SnackbarProps, variant?: SnackbarVariant) => void) => {
  const wsClient = createWebSocketClient();

  return createClient({
    url: import.meta.env.REACT_APP_GAPI_URL!,
    requestPolicy: 'cache-and-network',
    exchanges: [
      devtoolsExchange,
      defaultCacheExchange,
      createAuthExchange(showSnackbar),
      persistedExchange({
        preferGetForPersistedQueries: true,
      }),
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(operation as SubscribePayload, sink);
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
      fetchExchange, // The fetchExchange handles the HTTP request, so it should come last
    ],
    fetchOptions: () => ({
      credentials: 'include',
      headers: { Authorization: 'unauthenticated' },
    }),
    fetch: (...args) =>
      fetch(...args).then((response) => {
        const traceID = response.headers.get('x-cloud-trace-context');
        if (traceID) {
          storeTraceID(traceID);
        }
        return response;
      }),
  });
};
