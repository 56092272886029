import { TableRow, alpha, TableCell, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { Fragment } from 'react/jsx-runtime';
import { Hide } from 'components/Hide';
import { QualityChips, QualityChipVariant } from 'components/QualityChip';
import { BaseTheme } from 'config/base-theme';
import useIsViewport from 'hooks/useIsViewport';
import { mergeSx } from 'utils/merge-sx';
import {
  TableCellType,
  Rows,
  ExpandableTableRow,
  FontStyle,
  FontType,
  TableCell as TableCellData,
} from 'generated/graphql';

const cellStyle = (theme: BaseTheme, cellData: TableCellData) => ({
  padding: theme.spacing(1),
  ...(cellData.fontStyle === FontStyle.Bold && {
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.8, 0),
  }),
  ...(cellData.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
  ...(cellData.type === TableCellType.Icon && { width: theme.spacing(5) }),
  [theme.breakpoints.down('md')]: {
    width: 72,
    padding: theme.spacing(0.5),
  },
});

const SubTableRows = ({ rows }: { rows: ExpandableTableRow[] }) => {
  const isMobile = useIsViewport();
  const [openRows, setOpenRows] = useState<string[]>([]);

  const handleRowToggle = (id: string) => {
    setOpenRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <>
      {rows.map((row, index) => {
        return (
          <Fragment key={index}>
            <TableRow
              key={row.id}
              sx={(theme) => ({
                breakInside: 'avoid',
                cursor: 'pointer',
                background: row.highlighted ? alpha(theme.palette.warning.light, 0.4) : '',
              })}
              onClick={() => handleRowToggle(row.id)}
            >
              {row.cells.map((cellData, j) => {
                const align = cellData.align.toLowerCase() as 'left' | 'right';
                if (cellData.type === TableCellType.Icon) {
                  return (
                    <TableCell sx={(theme) => cellStyle(theme, cellData)} key={`${cellData.value + j}`} align={align}>
                      {cellData.value.length > 0 && (
                        <Box
                          component="img"
                          src={cellData.value}
                          alt=""
                          sx={(theme) => ({
                            verticalAlign: 'middle',
                            width: theme.spacing(5),
                            height: theme.spacing(5),
                            borderRadius: theme.spacing(1),
                          })}
                        />
                      )}
                    </TableCell>
                  );
                } else if (cellData.type === TableCellType.Chip) {
                  return (
                    <TableCell
                      key={`${cellData.value + j}`}
                      sx={mergeSx((theme) => cellStyle(theme, cellData))}
                      align={'center'}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <QualityChips
                          chips={cellData.buttons}
                          variant={
                            isMobile ? QualityChipVariant.BorderedCollapsed : QualityChipVariant.BorderedExpanded
                          }
                        />
                      </Box>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={`${cellData.value + j}`} sx={(theme) => cellStyle(theme, cellData)} align={align}>
                    {j === 0 && row.data?.__typename && (
                      <Hide>
                        <IconButton size="small" sx={{ padding: 0, marginRight: 0.5 }}>
                          {openRows.includes(row.id) ? (
                            <MdKeyboardArrowUp size={20} />
                          ) : (
                            <MdKeyboardArrowDown size={20} />
                          )}
                        </IconButton>
                      </Hide>
                    )}
                    <Box
                      sx={(theme) => ({
                        display: 'inline-block',
                        [theme.breakpoints.down('md')]: {
                          maxWidth: 160,
                          wordWrap: 'break-word',
                        },
                      })}
                    >
                      {cellData.value}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
            {openRows.includes(row.id) &&
              (row.data as Rows).rows &&
              (row.data as Rows).rows?.map((subRow) => (
                <TableRow key={subRow.id} sx={(theme) => ({ background: theme.palette.common.white })}>
                  {subRow.cells.map((cellData, j) => {
                    return (
                      <TableCell
                        key={`${cellData.value + j}`}
                        sx={(theme) => cellStyle(theme, cellData)}
                        align={cellData.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cellData.value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </Fragment>
        );
      })}
    </>
  );
};

export default SubTableRows;
