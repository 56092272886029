import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { ShippingMethod } from 'generated/graphql';
import useSearch from './useSearch';

const useDefaultShippingMethod = () => {
  const { isVillaProfile } = useWhiteLabelling();
  const [getParam] = useSearch();
  const defaultShippingMethod =
    ((getParam('shippingMethod') as ShippingMethod) ?? isVillaProfile) ? ShippingMethod.Direct : ShippingMethod.Hub;
  return defaultShippingMethod;
};

export default useDefaultShippingMethod;
