import React, { createContext, useContext, useEffect, useState } from 'react';
import useUser from 'hooks/useUser';
import { MarketLive, useMarketLiveSubscription } from 'generated/graphql';

const GlobalSubscriptionsContext = createContext<
  | {
      marketLive?: MarketLive;
      loading: boolean;
      error?: Error;
    }
  | undefined
>(undefined);

export const GlobalSubscriptionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isInitialised } = useUser();
  const [result, retry] = useMarketLiveSubscription({
    pause: !user?.email || !isInitialised,
  });

  const { data, fetching, error } = result;

  useEffect(() => {
    if (error && error.networkError) {
      retry();
    }
  }, [error, retry]);

  const value = {
    marketLive: data?.marketLive,
    loading: fetching,
    error: error?.networkError,
  };

  return <GlobalSubscriptionsContext.Provider value={value}>{children}</GlobalSubscriptionsContext.Provider>;
};

export const useGlobalSubscriptions = () => {
  const context = useContext(GlobalSubscriptionsContext);
  if (!context) {
    throw new Error('useGlobalSubscriptions must be used within a GlobalSubscriptionsProvider');
  }
  return context;
};
