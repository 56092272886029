import { AppBar, Badge, Box, Container, Link, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { IconType } from 'react-icons';
import { MdChat, MdDatasetLinked } from 'react-icons/md';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getPrefixedRoute, getRoutePrefix, getThemeVariant, ThemeVariant } from 'services/theming';
import IconButton from 'components/IconButton';
import MoreInfoTooltip from 'components/MoreInfoTooltip';
import { BaseTheme } from 'config/base-theme';
import { isStaging, isLocal, isProd } from 'config/env';
import { useBuyersMarket } from 'hooks/useBuyersMarket';
import { Carts, useBuyersMarketCart } from 'hooks/useBuyersMarketCart';
import useNavigationItems from 'hooks/useNavigationItems';
import useOnlineStatus, { OnlineStatus } from 'hooks/useOnlineStatus';
import useUser from 'hooks/useUser';
import { CartGroup, FormType } from 'generated/graphql';
import useCartInfo from '../hooks/useCartInfo';
import { FormDialogState } from './FormDialog';
import LazyFormDialog from './LazyFormDialog';
import ProfileConfigButton from './ProfileConfig/ProfileConfigButton';
import ProfileSwitch, { ProfileSwitchSize } from './ProfileSwitch';
import { useWhiteLabelling } from './WhiteLabellingProvider';
import { defaultFormDialogState } from './default-form-dialog-state';
import Logo from './logo-color.svg?react';
import LogoFreshmark from './logo-freshmark.svg?react';
import LogoVilla from './logo-villa.svg?react';

const noticeStyle = (theme: BaseTheme) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.warning.main,
  fontSize: '1.25rem',
  fontWeight: theme.typography.fontWeightBold,
});

const chatButtonStyle = (theme: BaseTheme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(0.75),
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
});

const HeaderLogo = () => {
  const themeVariant = getThemeVariant();
  return (
    <>
      {[ThemeVariant.Nile, ThemeVariant.Inputs].includes(themeVariant) && (
        <Box sx={{ maxWidth: 60, minWidth: 60 }}>
          <Logo />
        </Box>
      )}
      {themeVariant === ThemeVariant.FreshMark && (
        <Box sx={(theme) => ({ maxWidth: 120, minWidth: 120, marginBottom: theme.spacing(1) })}>
          <LogoFreshmark />
        </Box>
      )}
      {themeVariant === ThemeVariant.Villa && (
        <Box sx={() => ({ maxWidth: 100, minWidth: 100 })}>
          <LogoVilla width={100} height={50} />
        </Box>
      )}
      {themeVariant === ThemeVariant.Inputs && (
        <Typography variant="h4" color="secondary">
          Inputs
        </Typography>
      )}
    </>
  );
};

const OnlineStatusToolTip = () => {
  const status = useOnlineStatus();
  if (status !== OnlineStatus.Offline) {
    return null;
  }

  return (
    <MoreInfoTooltip
      sx={{ paddingY: 1, paddingLeft: 1 }}
      color="warning"
      description={
        <Typography>
          {`You're currently offline. While you can still access your stored data, please note that certain features are
          limited until you regain a network connection.`}
        </Typography>
      }
    />
  );
};

const HeaderNotice = () => {
  return (
    <>
      {isProd && isLocal && <Box sx={noticeStyle}>🚧 Production API 🚧</Box>}
      {isStaging && <Box sx={noticeStyle}>🚧 Staging API 🚧</Box>}
      {isLocal && !isStaging && !isProd && <Box sx={noticeStyle}>🏗️ Local API🏗️</Box>}
    </>
  );
};

const HeaderBadge = ({ Icon }: { Icon: IconType }) => {
  return (
    <Badge
      badgeContent={0}
      sx={(theme) => ({
        marginLeft: 0,
        '& > svg': {
          fontSize: '1.6em',
          margin: theme.spacing(-0.25, 0, 0, 0.5),
        },
        '& > span': {
          height: 16,
          transform: 'scale(1) translate(65%, -70%)',
          background: theme.palette.info.main,
        },
      })}
    >
      <Icon size={16} />
    </Badge>
  );
};

const HeaderProviderBadge = ({ Icon }: { Icon: IconType }) => {
  const { cartGroup, carts } = useBuyersMarketCart();
  const { trucks } = useBuyersMarket();

  const { cartGroupCases } = useCartInfo(cartGroup as CartGroup, trucks, carts as Carts);

  return (
    <Badge
      badgeContent={cartGroupCases.toString()}
      sx={(theme) => ({
        marginLeft: theme.spacing(-0.5),
        '& > svg': {
          fontSize: '1.6em',
          margin: theme.spacing(-0.25, 2, 0, 0),
        },
        '& > span': {
          height: 16,
          transform: 'scale(1) translate(30%, -10%)',
          background: theme.palette.success.light,
        },
      })}
    >
      <Icon size={16} />
    </Badge>
  );
};

const Header = () => {
  const { isFreshmarkProfile } = useWhiteLabelling();
  const location = useLocation();
  const headerLinks = useNavigationItems();
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { user } = useUser();
  const prefix = getRoutePrefix();
  const setSeedFormDialog = () => {
    setFormDialog({
      open: true,
      editIDs: [],
      eTags: [],
      type: FormType.SeededPresets,
      addButton: false,
    });
  };

  const handleDialogClose = () => {
    setFormDialog(defaultFormDialogState);
  };

  const handleDialogSubmitSuccess = () => {
    setFormDialog(defaultFormDialogState);
  };

  return (
    <>
      <AppBar enableColorOnDark sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
        <Toolbar
          sx={(theme) => ({
            background: theme.palette.background.paper,
            borderBottom: `solid 1px ${theme.palette.divider}`,
            padding: 0,
            minHeight: '48px',
            [theme.breakpoints.up('sm')]: {
              minHeight: '48px',
            },
          })}
        >
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 0,
              maxWidth: '100% !important',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
              })}
            >
              <HeaderLogo />
              <OnlineStatusToolTip />
              <HeaderNotice />
              {(isLocal || isStaging) && (
                <IconButton onClick={setSeedFormDialog}>
                  <MdDatasetLinked size={20} />
                </IconButton>
              )}
              <Box sx={{ marginLeft: 2 }}>
                <ProfileConfigButton />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {headerLinks.map((link) => {
                const { label, path, icon: Icon } = link;
                const isCart = label === 'Cart';
                const adjustedPrefix = prefix.length === 0 ? '/' : prefix;
                const isActivePath =
                  (path.includes(location.pathname) && location.pathname !== adjustedPrefix) ||
                  (path.includes(location.search) && location.search.length > 0);

                return (
                  <Link
                    key={path}
                    component={RouterLink}
                    to={path}
                    sx={(theme) => ({
                      backgroundColor: isCart ? theme.palette.tertiary.main : theme.palette.common.white,
                      padding: isCart ? theme.spacing(0.5, 2) : theme.spacing(0.5, 1.5),
                      borderRadius: isCart ? theme.spacing(10) : theme.spacing(1),
                      color: isActivePath ? theme.palette.secondary.main : theme.palette.primary.main,
                      '&:hover': {
                        color: theme.palette.primary.light,
                      },
                      fontSize: '0.98rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    {!isCart && label}
                    {isCart ? <HeaderProviderBadge Icon={Icon} /> : <HeaderBadge Icon={Icon} />}
                  </Link>
                );
              })}

              {user && <ProfileSwitch size={ProfileSwitchSize.Small} isButton />}
              {isFreshmarkProfile ? (
                <IconButton
                  as={RouterLink}
                  to={getPrefixedRoute('/chat')}
                  sx={{ chatButtonStyle }}
                  size="small"
                  aria-label="Chat"
                >
                  <MdChat size={20} />
                </IconButton>
              ) : null}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      {formDialog.open && (
        <LazyFormDialog
          type={formDialog.type}
          editIDs={formDialog.editIDs}
          open={formDialog.open}
          addButton={formDialog.addButton}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmitSuccess}
        />
      )}
    </>
  );
};

export default Header;
