import { Dayjs } from 'dayjs';
import { ReactNode, useContext, useMemo, useState, createContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { CombinedError, UseQueryState } from 'urql';
import { tableScreenInputFromString, tableScreenInputToString, TableScreenQueryParam } from 'screens/TablesScreen';
import { DateType, ShipmentsQuery, TablesScreenInput, useShipmentsQuery } from 'generated/graphql';
import useBackoffInterval from './useBackoffInterval';
import useProfileConfig from './useProfileConfig';
import useSearch from './useSearch';
import { SnackbarVariant, useSnackbar } from './useSnackBar';
import { useSyncedMutableOfflineState } from './useSyncedMutableOfflineState';
import useUser from './useUser';

const Context = createContext<{
  fetching: boolean;
  isRevalidating: boolean;
  error: CombinedError | undefined;
  shipments: ShipmentsQuery['shipments'] | undefined;
  result: UseQueryState<ShipmentsQuery>;
  onRefetch: () => void;
  input: TablesScreenInput;
  onInputChange: (input: TablesScreenInput) => void;
  dateRange: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    label?: string;
    onDatesChange: (startDate: Dayjs, endDate: Dayjs) => void;
    onReset: () => void;
  };
} | null>(null);

const DATE_FORMAT = 'D MMM';

export const ShipmentsProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { isPendingReview, isRegistering, user, isAdmin } = useUser();
  const [getParam, setParam] = useSearch();

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const [input, setInput] = useState<TablesScreenInput>(
    tableScreenInputFromString(getParam(TableScreenQueryParam.Input) || 'null') || {
      search: getParam(TableScreenQueryParam.Search) || '',
      tabs: [],
      dates: [],
    },
  );

  const isShipments = location.pathname.includes('shipments');
  const isQueryPaused = !input || isPendingReview || isRegistering || !user?.profileId || !user?.status;
  const { profileID: paramProfileID } = useProfileConfig();
  const { showSnackbar } = useSnackbar();

  const profileID = useMemo(
    () => (isAdmin && paramProfileID ? paramProfileID : user?.profileId),
    [isAdmin, paramProfileID, user?.profileId],
  );

  const [result, refetch] = useShipmentsQuery({
    variables: {
      input: {
        ...input,
        dates:
          !startDate || !endDate
            ? []
            : [{ start: startDate?.toISOString(), end: endDate?.toISOString(), type: DateType.ExpectedReceiveDate }],
      },
      profileID: profileID ? profileID?.toString() : '0',
    },
    pause: isQueryPaused || !profileID,
  });

  useEffect(() => {
    if (!input || !isShipments) return;
    setParam(TableScreenQueryParam.Input, tableScreenInputToString(input));
    setParam(TableScreenQueryParam.Search, input.search);
  }, [input, setParam, isShipments]);

  const shouldRefetch = !!result.data && !result.fetching;
  useBackoffInterval(refetch, shouldRefetch);

  const [shipmentsData] = useSyncedMutableOfflineState(result.data, ['shipments', input]);

  useEffect(() => {
    if (result.error) showSnackbar({ message: result.error.message }, SnackbarVariant.ERROR);
  }, [result.error, showSnackbar]);

  const handleDatesChange = useCallback((startDate: Dayjs, endDate: Dayjs) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const value = useMemo(
    () => ({
      fetching: result.fetching,
      isRevalidating: result.stale,
      error: result.error,
      shipments: shipmentsData?.shipments,
      onRefetch: refetch,
      input,
      onInputChange: setInput,
      result,
      dateRange: {
        startDate,
        endDate,
        onDatesChange: handleDatesChange,
        label: !startDate || !endDate ? undefined : `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        onReset: () => {
          setStartDate(null);
          setEndDate(null);
        },
      },
    }),
    [result, refetch, input, setInput, shipmentsData, startDate, endDate, handleDatesChange],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useShipments = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useShipments must be used within a ShipmentsProvider');
  return context;
};
