import { Box, IconButton, Stack, TableBody, TableRow, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import useIsViewport from 'hooks/useIsViewport';
import {
  ExpandableTableRow as ExpandableTableRowType,
  TableType,
  TableAction,
  TableColumn,
  FontType,
  FontStyle,
  ExpandableTableRowGroup,
} from 'generated/graphql';
import ExpandableTableCell from './ExpandableTableCell';
import ExpandableTableRow from './ExpandableTableRow';

const getNestedData = (index: number, rows: ExpandableTableRowType[]) => {
  let nestedData = [];
  for (let i = index + 1; i < rows.length; i++) {
    if (rows[i].data === null) {
      break;
    }
    nestedData.push(rows[i]);
  }
  return nestedData;
};

const ExpandableTableGroup = ({
  group,
  index,
  isFetching,
  hasPending,
  groups,
  columns,
  type,
  footer,
  actions,
  refresh,
  onFieldUpdate,
  onCustomSchemeRequest,
  setShowGraphs,
  setSelectedRows,
}: {
  type: TableType;
  groups: ExpandableTableRowGroup[];
  group: ExpandableTableRowGroup;
  index: number;
  actions: TableAction[];
  columns: TableColumn[];
  isFetching: boolean;
  hasPending: boolean;
  footer: ReactNode;
  refresh: () => void;
  onFieldUpdate(rowId: string, cellIndex: number, newValue: string): void;
  onCustomSchemeRequest(url: string): void;
  setShowGraphs(showGraph: boolean): void;
  setSelectedRows(selectedRow: string[]): void;
}) => {
  const isMobile = useIsViewport();
  const [selectedRowsDictionary, setSelectedRowsDictionary] = useState<{ [key: string]: any }>({});
  const showRowSelectionCheckboxes = !!actions?.some((action) => action.minRequiredRowSelection >= 1);
  const isSingleRow = groups.length === 1 && (groups[0]?.rows?.length ?? 0) === 1;
  const isStatisticalType = type === TableType.Statistics;
  const isReportingType = type === TableType.Reporting;
  const hasTotals = group.totals && group.totals.length > 0;
  const hasMobileTotal = isMobile && group?.totals?.some((totalCell) => totalCell.mobile);
  const showValueRow = !isStatisticalType && hasTotals && (!isMobile || hasMobileTotal);
  const isExpandable = (group?.rows?.length ?? 0) > 0;
  const [openGroup, setOpenGroup] = useState(true);

  const handleRowClick = (isExpandable: boolean) => {
    if (!isExpandable) {
      return;
    }
    setOpenGroup((value) => !value);
  };

  useEffect(() => {
    setSelectedRows(Object.keys(selectedRowsDictionary).filter((key) => selectedRowsDictionary[key]));
  }, [selectedRowsDictionary, setSelectedRows]);

  const handleCheckboxToggle = (rowId: string, value: boolean) => {
    setSelectedRowsDictionary((dict) => ({
      ...dict,
      [rowId]: value,
    }));
  };

  return (
    <TableBody key={+index}>
      {isStatisticalType && hasTotals && (
        <TableRow key={index} onClick={() => handleRowClick(isExpandable)} sx={{ breakInside: 'avoid' }}>
          {group?.totals?.map((total, j) => (
            <ExpandableTableCell
              key={j}
              align={total.align.toLowerCase() as 'left' | 'right'}
              sx={(theme) => ({
                '&.MuiTableCell-root': {
                  background: theme.palette.grey[50],
                  borderRight: j > 0 ? 'hidden' : '',
                  borderLeft: j === 0 ? 'hidden' : '',
                  paddingY: theme.spacing(1),
                  ...(j === 0 && {
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
                    borderRight: 'none',
                  }),
                },
              })}
            >
              <Stack
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(1),
                  '&.MuiStack-root': {
                    justifyContent: (total.align.toLowerCase() as 'left' | 'right') ?? 'left',
                  },
                })}
              >
                {isExpandable && (
                  <IconButton size="small" sx={{ padding: 0 }}>
                    {openGroup ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                  </IconButton>
                )}
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    fontFamily: `${
                      total.fontType === FontType.Normal
                        ? theme.typography.fontFamily
                        : total.fontType === FontType.Mono
                          ? 'Roboto Mono'
                          : ''
                    }`,
                    fontWeight: `${total.fontStyle === FontStyle.Bold ? theme.typography.fontWeightBold : ''}`,
                  })}
                >
                  {total.value}
                </Typography>
              </Stack>
            </ExpandableTableCell>
          ))}
          <ExpandableTableCell
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: theme.palette.grey[50],
                borderRight: 'hidden',
              },
            })}
            colSpan={columns.length - (group?.totals?.length ?? 0)}
          />
          <ExpandableTableCell // Adds additional space after table columns for pricing dashboard sales table
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: theme.palette.grey[50],
                borderRight: 'hidden',
                maxWidth: theme.spacing(1),
                minWidth: theme.spacing(1),
              },
            })}
          />
        </TableRow>
      )}
      {isStatisticalType
        ? group.rows?.map(
            (row, k) =>
              row.data === null && // TODO: Maybe add a boolean on BE instead of doing null check
              openGroup &&
              isExpandable && (
                <ExpandableTableRow
                  key={k}
                  initExpanded
                  isFetching={isFetching}
                  hasPending={hasPending}
                  data={row}
                  nestedData={getNestedData(k, group.rows)}
                  type={type}
                  refresh={refresh}
                  setShowGraphs={setShowGraphs}
                  highlighted={row.highlighted}
                />
              ),
          )
        : group.rows?.map((row, k) => (
            <ExpandableTableRow
              isFetching={isFetching}
              hasPending={hasPending}
              key={k}
              data={row}
              type={type}
              initExpanded={isSingleRow || isReportingType}
              refresh={refresh}
              hideLastBorder={!footer && index === group.rows.length - 1}
              highlighted={row.highlighted}
              showCheckbox={showRowSelectionCheckboxes}
              checked={selectedRowsDictionary[row.id]}
              onCheckboxToggle={(newValue) => handleCheckboxToggle(row.id, newValue)}
              onFieldChange={(cellIndex, newValue) => {
                onFieldUpdate(row.id, cellIndex, newValue);
              }}
              onCustomSchemeRequest={onCustomSchemeRequest}
            />
          ))}
      {showValueRow && (
        <TableRow sx={{ breakInside: 'avoid' }}>
          {group?.totals
            ?.filter((total) => !isMobile || total.mobile)
            .map((total, j) => (
              <ExpandableTableCell key={+j} align={total.align.toLowerCase() as 'left' | 'right'}>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...(total.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
                    ...(total.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
                    ...(total.fontStyle === FontStyle.Bold && {
                      fontWeight: theme.typography.fontWeightMedium,
                    }),
                  })}
                >
                  {total.value}
                </Box>
              </ExpandableTableCell>
            ))}
        </TableRow>
      )}
    </TableBody>
  );
};

export default ExpandableTableGroup;
