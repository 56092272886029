import { Draft, produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_PAGE_NUMBER } from 'utils/constants';
import {
  Price,
  WeekInfo,
  Button,
  MarketSellerSection,
  ProductTypeSectionNew,
  ProductWeightSectionNew,
  ProductDataNew,
  SupplyNew,
  SupplyDate,
} from 'generated/graphql';

const stitchSeasonPlan = (weekInfo: WeekInfo[], weekInfoNew: WeekInfo[]) => {
  const indexesToSplice: Number[] = [];

  const stitchedDates = weekInfo.map((info) => {
    const index = weekInfoNew.findIndex((curr) => curr.start === info.start);
    if (index === -1) {
      return { ...info };
    }

    indexesToSplice.push(index);
    return {
      ...weekInfoNew[index],
    };
  });

  const dataToAdd = weekInfoNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedDates || []), ...(dataToAdd || [])];
};

const stitchDeliveryDates = (dates: SupplyDate[], datesNew: SupplyDate[]) => {
  const indexesToSplice: Number[] = [];

  const stitchedDates = dates.map((date) => {
    const index = datesNew.findIndex((curr) => curr.csvDate === date.csvDate);
    if (index === -1) {
      return { ...date };
    }

    indexesToSplice.push(index);
    return {
      ...datesNew[index],
    };
  });

  const dataToAdd = datesNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedDates || []), ...(dataToAdd || [])];
};

const stitchSupply = (supply: SupplyNew, supplyNew: SupplyNew): SupplyNew => {
  if (!supply || !supplyNew) {
    return supplyNew;
  }

  return {
    ...supplyNew,
    dates: stitchDeliveryDates(supply.dates, supplyNew.dates),
    marketDates: stitchDeliveryDates(supply.marketDates, supplyNew.marketDates),
    seasonPlan: stitchSeasonPlan(supply.seasonPlan, supplyNew.seasonPlan),
  };
};

const stitchPrices = (prices: Price[], pricesNew: Price[]): Price[] => {
  const indexesToSplice: Number[] = [];

  const stitchedPrices = prices.map((price) => {
    const index = pricesNew.findIndex((curr) => curr.code === price.code);
    if (index === -1) {
      return { ...price };
    }

    indexesToSplice.push(index);
    return {
      ...price,
    };
  });

  const dataToAdd = pricesNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedPrices || []), ...(dataToAdd || [])];
};

const stitchProductData = (products: ProductDataNew[], productsNew: ProductDataNew[]): ProductDataNew[] => {
  const indexesToSplice: Number[] = [];

  const stitchedProducts = products.map((product) => {
    const index = productsNew.findIndex((curr) => curr.productID === product.productID);
    if (index === -1) {
      return { ...product };
    }

    const productNew = productsNew[index];
    indexesToSplice.push(index);
    return {
      ...productNew,
      prices: stitchPrices(product.prices, productNew.prices),
      supply: stitchSupply(product.supply, productNew.supply),
    };
  });

  const dataToAdd = productsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedProducts || []), ...(dataToAdd || [])];
};

const stitchProductButtons = (buttons: Button[], buttonsNew: Button[]): Button[] => {
  const indexesToSplice: Number[] = [];

  const stitchedButtons = buttons.map((button) => {
    const index = buttonsNew.findIndex((curr) => curr.value === button.value);
    if (index === -1) {
      return { ...button };
    }

    indexesToSplice.push(index);
    return {
      ...buttonsNew[index],
    };
  });

  const dataToAdd = buttonsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedButtons || []), ...(dataToAdd || [])];
};

const stitchProductWeights = (
  productWeights: ProductWeightSectionNew[],
  productWeightsNew: ProductWeightSectionNew[],
): ProductWeightSectionNew[] => {
  const indexesToSplice: Number[] = [];

  const stitchedProductWeights = productWeights.map((productWeight) => {
    const index = productWeightsNew.findIndex((curr) => curr.name === productWeight.name);
    if (index === -1) {
      return { ...productWeight };
    }

    indexesToSplice.push(index);
    return {
      ...productWeight,
      products: stitchProductData(productWeight.products, productWeightsNew[index].products),
    };
  });

  const dataToAdd = productWeightsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedProductWeights || []), ...(dataToAdd || [])];
};

// Stiching product types
const stitchProductTypes = (
  sections: ProductTypeSectionNew[],
  sectionsNew: ProductTypeSectionNew[],
): ProductTypeSectionNew[] => {
  const indexesToSplice: Number[] = [];

  const stitchedSections = sections.map((section) => {
    const index = sectionsNew.findIndex((curr) => curr.productTypeID === section.productTypeID);
    if (index === -1) {
      return { ...section };
    }

    const sectionNew = sectionsNew[index];
    indexesToSplice.push(index);

    return {
      ...section,
      buttons: stitchProductButtons(section.buttons, sectionNew.buttons),
      productTypeEnabled: sectionNew.productTypeEnabled || section.productTypeEnabled,
      productWeights: stitchProductWeights(section.productWeights, sectionNew.productWeights),
    };
  });

  const dataToAdd = sectionsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(stitchedSections || []), ...(dataToAdd || [])];
};
const stitchProducts = (
  productSections: MarketSellerSection | undefined,
  productSectionsNew: MarketSellerSection,
): MarketSellerSection => {
  if (!productSections || !productSectionsNew) {
    return productSectionsNew;
  }

  const sections = stitchProductTypes(productSections.sections, productSectionsNew.sections);
  const suggestions = new Set([...(productSections.suggestions ?? []), ...productSectionsNew.suggestions]) ?? new Set();

  return {
    ...productSections,
    suggestions: [...suggestions],
    sections: sections,
    pageInfo: productSectionsNew.pageInfo,
  };
};

export const useStitchProductsNew = (data: MarketSellerSection | undefined, allowReset: boolean) => {
  const [productSections, setProductSections] = useState<MarketSellerSection>();
  const [isStitching, setIsStitching] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setIsStitching(true);
      const shouldReset = data.pageInfo.page === DEFAULT_PAGE_NUMBER && allowReset;
      setProductSections((prev) => (shouldReset ? data : stitchProducts(prev, data)));
    }
  }, [data, allowReset]);

  useEffect(() => {
    setIsStitching(false);
  }, [productSections]);

  const set = useCallback((updater: (draft: Draft<MarketSellerSection | undefined>) => void) => {
    setProductSections(produce(updater));
  }, []);

  return {
    products: productSections,
    stitching: isStitching,
    set,
  };
};
