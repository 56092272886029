import { Alert, AlertColor, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { MdClose } from 'react-icons/md';

const DEFAULT_HIDE_DURATION = 10000;

const ActionSnackbar = ({
  isOpen,
  title,
  actionTitle,
  isNestedAlert = false,
  alertSeverity = 'success',
  onAction,
  onClose,
}: {
  isOpen: boolean;
  title: string;
  actionTitle: string;
  isNestedAlert?: boolean;
  alertSeverity?: AlertColor;
  onAction: () => void;
  onClose: () => void;
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={DEFAULT_HIDE_DURATION}
      onClose={onClose}
      message={isNestedAlert ? '' : title}
      action={
        <>
          {isNestedAlert ? undefined : (
            <>
              <Button color="secondary" size="small" onClick={onAction}>
                {actionTitle}
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                <MdClose size={20} />
              </IconButton>
            </>
          )}
        </>
      }
    >
      {isNestedAlert ? (
        <Alert
          onClose={onClose}
          severity={alertSeverity}
          variant="filled"
          icon={false}
          sx={(theme) => ({
            width: '100%',
            padding: theme.spacing(1, 2),
            '.MuiAlert-action': {
              padding: theme.spacing(0),
              color: theme.palette.common.white,
            },
            '.MuiAlert-message': {
              fontSize: theme.typography.body1,
            },
          })}
          action={
            <Stack direction="row" justifyContent="center" sx={(theme) => ({ paddingLeft: theme.spacing(1) })}>
              <Button
                onClick={onAction}
                sx={(theme) => ({
                  color: theme.palette.common.white,
                })}
              >
                {actionTitle}
              </Button>
              <IconButton aria-label="close" color="inherit" onClick={onClose}>
                <MdClose size={20} />
              </IconButton>
            </Stack>
          }
        >
          {title}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};

export default ActionSnackbar;
