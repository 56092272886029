import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router';
import { getRoutePrefix } from 'services/theming';
import useUser from 'hooks/useUser';
import { hasRobotToken } from 'utils/hasRobotToken';
import HomeRedirect from './HomeRedirect';

export const RequiresAuth = ({ allowRobot = false }: { allowRobot?: boolean }) => {
  const prefix = getRoutePrefix();

  const { user } = useUser();
  const navigate = useNavigate();
  const hasToken = hasRobotToken();

  useEffect(() => {
    if (user || (hasToken && allowRobot)) {
      return;
    }
    //Only navigate when user is not logged in and doesn't have a robot token
    navigate(`${prefix}/login`, { replace: true });
  }, [navigate, prefix, hasToken, user, allowRobot]);

  if (user || (hasToken && allowRobot)) {
    return <Outlet />;
  }

  return <Navigate to={`${prefix}/login`} replace />;
};
