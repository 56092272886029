import { Box, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { Field, FieldGroup, FieldGroupOrientation, FormType } from 'generated/graphql';
import DynamicInputField from './DynamicInputField';

const DynamicFieldGroup = ({
  groupIndex,
  fieldGroup,
  fieldGroups,
  isLoading,
  numOfGroups,
  onFieldUpdate,
  setNumOfGroups,
  formType,
}: {
  groupIndex: number;
  fieldGroup: FieldGroup;
  fieldGroups: FieldGroup[];
  isLoading?: boolean;
  numOfGroups: number;
  onFieldUpdate: (field: Field, value: string, values?: string[]) => void;
  setNumOfGroups: (numOfGroups: number) => void;
  formType: FormType;
}) => {
  const deleteGroup = () => {
    fieldGroups.slice(groupIndex, numOfGroups).forEach((group, grpIndex, groupsArray) => {
      group.fields.forEach((field, fldIndex) => {
        if (grpIndex === numOfGroups - 1) {
          onFieldUpdate(field, '', []);
        } else {
          onFieldUpdate(
            field,
            groupsArray[grpIndex + 1]?.fields[fldIndex].value ?? '',
            groupsArray[grpIndex + 1]?.fields[fldIndex].values ?? [],
          );
        }
      });
    });
    setNumOfGroups(numOfGroups - 1);
  };

  return (
    <Box
      sx={
        fieldGroup.orientation === FieldGroupOrientation.Horizontal
          ? {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              gap: 1,
            }
          : {}
      }
    >
      {fieldGroup.fields.map((field, index, fields) => (
        <DynamicInputField
          key={`${field.id}-${field.fieldGroupID}`}
          field={field}
          disabled={isLoading}
          autoFocus={index === 0}
          allFields={fields}
          onChange={(value, values) => onFieldUpdate(field, value, values)}
          sx={index === 0 ? { width: '80%' } : { width: '20%' }}
          formType={formType}
        />
      ))}
      <IconButton disabled={isLoading} size="small" aria-label="close" color="inherit" onClick={deleteGroup}>
        <MdClose size={16} />
      </IconButton>
    </Box>
  );
};
const DynamicFieldGroups = ({
  fieldGroups,
  isLoading,
  onFieldUpdate,
  formType,
}: {
  fieldGroups: FieldGroup[];
  isLoading?: boolean;
  onFieldUpdate: (field: Field, value: string, values?: string[]) => void;
  formType: FormType;
}) => {
  const [numOfGroups, setNumOfGroups] = useState(1);
  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          maxHeight: 320,
          overflowY: 'auto',
        },
      })}
    >
      {fieldGroups
        .map((fieldGroup, index) => {
          return (
            <DynamicFieldGroup
              key={`${fieldGroup.fields[0].fieldGroupID}-${fieldGroup.fields[0].value}`}
              groupIndex={index}
              fieldGroup={fieldGroup}
              fieldGroups={fieldGroups}
              isLoading={isLoading}
              numOfGroups={numOfGroups}
              setNumOfGroups={setNumOfGroups}
              onFieldUpdate={onFieldUpdate}
              formType={formType}
            />
          );
        })
        .slice(0, numOfGroups)}
      <Button
        fullWidth
        sx={(theme) => ({ margin: theme.spacing(1, 0, 1) })}
        variant="outlined"
        color="primary"
        startIcon={<MdAdd size={14} />}
        disabled={isLoading || numOfGroups === fieldGroups.length}
        onClick={() => {
          if (numOfGroups < fieldGroups.length) setNumOfGroups(numOfGroups + 1);
        }}
      >
        {numOfGroups < fieldGroups.length ? 'Add' : 'Max'}
      </Button>
    </Box>
  );
};
export default DynamicFieldGroups;
