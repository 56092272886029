import { SnackbarProps } from '@mui/material';
import { authExchange } from '@urql/exchange-auth';
import { signOut } from 'firebase/auth';
import { Client, createClient as createWSClient } from 'graphql-ws';
import { auth } from 'services/firebase';
import { SnackbarVariant } from 'hooks/useSnackBar';
import { hasRobotToken } from 'utils/hasRobotToken';
import refreshFirebaseUser from 'utils/refresh-firebase-user';
import { clearStore } from './idb-store';
import { logError } from './logging';
import { getAdminProfileFromVariant, getPrefixedRoute, NILE_ADMIN_PROFILE_ID } from './theming';

const getTokenExpiryTime = (token: string) => {
  // Split the token into parts
  const parts = token.split('.');
  if (parts.length !== 3) {
    return null;
  }

  // Decode the payload (second part)
  const payload = JSON.parse(atob(parts[1]));

  // Get the expiry time
  const expiryTime = payload.exp;

  // Convert to milliseconds and return as a Date object
  return expiryTime ? new Date(expiryTime * 1000) : null;
};

const getToken = async (forceRefresh = false) => {
  // If a "robotToken" is present in the window hash, use it as the token.
  // Used for PDF generation and other cloud jobs that need to authenticate
  if (hasRobotToken()) {
    const robotToken = window.location.search.split('robotToken=')[1];
    return `robotToken=${robotToken}`;
  }

  return resetToken(forceRefresh);
};

const wssURL = import.meta.env.REACT_APP_GAPI_URL?.replace('http', 'ws');

export const createWebSocketClient = () => {
  const platformValue = getAdminProfileFromVariant();
  return createWSClient({
    url: wssURL,
    connectionParams: async () => {
      const token = await getToken();
      return { Authorization: `Bearer ${token}`, 'X-Platform': platformValue || NILE_ADMIN_PROFILE_ID.toString() };
    },
    keepAlive: 30000, // 30 seconds to prevent 5-minute timeout on Safari
    retryAttempts: Infinity, // Automatic reconnection if needed
    retryWait: async () => await new Promise((resolve) => setTimeout(resolve, 5000)), // 5-second retry delay
  });
};

export const reconnectWebSocketClient = (wsClient: Client) => {
  wsClient.dispose();
  return createWebSocketClient();
};

export const resetToken = async (forceRefresh = false) => {
  const firebaseUser = auth.currentUser;
  if (!firebaseUser) return null;
  const token = await refreshFirebaseUser({
    user: firebaseUser,
    forceRefresh: forceRefresh,
  });
  return token;
};

export const resetStorage = async () => {
  localStorage.clear();
  await clearStore();
  sessionStorage.clear();
};

export const logout = async (
  withRedirect = true,
  showSnackbar?: (props: SnackbarProps, variant?: SnackbarVariant) => void,
) => {
  await signOut(auth)
    .then(async () => {
      await resetToken(true);
      await signOut(auth);
      await resetStorage();
      sessionStorage.clear();
      if (withRedirect) {
        window.location.href = getPrefixedRoute('login');
      }
    })
    .catch((error) => {
      logError('Signout failed', {
        message: `Signout error: ${error}`,
      });
      if (showSnackbar)
        showSnackbar(
          {
            message:
              'An error occurred while logging you out. Please try again. If the issue persists, feel free to contact our team for assistance.',
            autoHideDuration: 3000,
            color: 'error',
          },
          SnackbarVariant.ERROR,
        );
    });
};

const createAuthExchange = (showSnackbar?: (props: SnackbarProps, variant?: SnackbarVariant) => void) => {
  let wsClient = createWebSocketClient();
  const reconnectWSClient = () => {
    wsClient = reconnectWebSocketClient(wsClient);
  };

  let token: string | null = null; // Track the token

  return authExchange(async (utils) => {
    token = await getToken(); // Fetch the token once when creating the exchange

    return {
      addAuthToOperation(operation) {
        const platformValue = getAdminProfileFromVariant();
        const withPlatform = utils.appendHeaders(operation, {
          'X-Platform': platformValue || NILE_ADMIN_PROFILE_ID.toString(),
        });

        if (token) {
          return utils.appendHeaders(withPlatform, {
            Authorization: `Bearer ${token}`,
          });
        }
        return withPlatform;
      },
      willAuthError() {
        let tokenHasExpired = false;
        if (token) {
          const expiryTime = getTokenExpiryTime(token);
          tokenHasExpired = !expiryTime || expiryTime < new Date();
        }

        return !token || tokenHasExpired;
      },
      didAuthError(error, operation) {
        const isUnauthorized = error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
        if (isUnauthorized) {
          logError('Unauthorized request', {
            message: `Unauthorized request: ${error.graphQLErrors[0].message}. Extension code: ${error.graphQLErrors[0].extensions?.code}`,
          });
          logout(true);
          return false;
        }

        const isErrorMessage = error.graphQLErrors.some(
          (e) => e.extensions?.code && ['UNAUTHORIZED', 'ERROR_MESSAGE'].includes(e.extensions.code.toString()),
        );
        if (isErrorMessage) {
          showSnackbar &&
            showSnackbar(
              {
                message: error.graphQLErrors[0].message,
                autoHideDuration: 3000,
                color: 'error',
              },
              SnackbarVariant.ERROR,
            );
          return false;
        }

        const requestUrl = operation.context?.url;
        const wasWebSocketRequest = requestUrl && requestUrl.startsWith('wss://');

        if (wasWebSocketRequest) {
          // Refresh the auth token and reconnect the websocket client regardless of type of request
          reconnectWSClient();
          return false;
        }

        return error.graphQLErrors.some((e) => e.extensions?.code === 'REFRESH_TOKEN');
      },
      async refreshAuth() {
        try {
          token = await getToken(true); // Update the token
        } catch {
          await logout(true);
        }
      },
    };
  });
};

export default createAuthExchange;
