import { Box, SxProps } from '@mui/material';
import { BaseTheme } from 'config/base-theme';
import { getIconSrc } from './icons';

interface ProductIconProps {
  sku: string;
  className?: string;
  sx?: SxProps<BaseTheme>;
  size?: number;
}

const ProductIcon = ({ sku, sx = {}, className = '', size = 30 }: ProductIconProps) => {
  const src = getIconSrc(sku?.toLowerCase() ?? '');
  if (!src) {
    return null;
  }
  return (
    <>
      <Box
        component="img"
        src={src}
        sx={{
          position: 'relative',
          width: size,
          height: size,
          padding: (theme) => theme.spacing(0.25),
          ...sx,
        }}
        className={className}
        alt=""
      />
    </>
  );
};

export default ProductIcon;
