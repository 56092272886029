import { Box, Slider, SliderMarkLabel, SliderThumb, Stack, Typography } from '@mui/material';
import { BaseTheme } from 'config/base-theme';
import { ChartData, IconType, ThemeColor } from 'generated/graphql';

interface ThumbData {
  label: string;
  displayValue: string;
  color: ThemeColor;
  icon: IconType;
}

const getThumbIcon = (icon: IconType) => {
  switch (icon) {
    case IconType.Pipe:
      return 'pipe-thumb';
    default:
      return 'dot-thumb';
  }
};

const getThumbColor = (color: ThemeColor) => {
  switch (color) {
    case ThemeColor.Primary:
      return 'primary-color';
    case ThemeColor.Secondary:
      return 'secondary-color';
    case ThemeColor.Info:
      return 'info-color';
    default:
      return 'default-color';
  }
};

const getDisplayValueColor = (color: ThemeColor, theme: BaseTheme) => {
  switch (color) {
    case ThemeColor.Primary:
      return theme.palette.primary.main;
    case ThemeColor.Secondary:
      return theme.palette.secondary.main;
    case ThemeColor.Info:
      return theme.palette.info.main;
    default:
      return theme.palette.common.black;
  }
};

function AvgMaxMinThumb(props: any) {
  const { children, className, thumbsdata, ...other } = props;
  const currentThumb: ThumbData = thumbsdata.find((style: ThumbData) => style.label === children.props['aria-label']);

  return (
    <Stack>
      <SliderThumb
        {...other}
        className={`${className} ${getThumbIcon(currentThumb?.icon)} ${getThumbColor(currentThumb?.color)}-thumb`}
      >
        <Typography
          key={`${currentThumb?.displayValue}-displayValue`}
          sx={(theme) => ({
            marginBottom: theme.spacing(6),
            color: getDisplayValueColor(currentThumb?.color, theme),
          })}
        >
          {currentThumb?.displayValue}
        </Typography>
        {children}
      </SliderThumb>
    </Stack>
  );
}

function AvgMaxMinMark(props: any) {
  const { children, className, thumbsdata, ...other } = props;
  const currentThumb: ThumbData = thumbsdata.find((style: ThumbData) => style.label === children);

  return (
    <Stack>
      <SliderMarkLabel {...other} className={`${className}  ${getThumbColor(currentThumb?.color)}-label`}>
        {children}
      </SliderMarkLabel>
    </Stack>
  );
}

const AvgMaxMinChartType = ({ data }: { data?: ChartData }) => {
  const chartPoints = data?.periods[0].multiSeries[0].data;

  if (!chartPoints) return null;

  const sliderValues = chartPoints.map((chartPoint) => chartPoint.y ?? 0);
  const sliderLabels = chartPoints.map((chartPoint) => chartPoint.tooltipLabel ?? 0);
  const marks = sliderValues.map((value, index) => {
    return { value: value, label: sliderLabels[index] };
  });

  const maxVal = Math.max(...sliderValues);
  const minVal = Math.min(...sliderValues);

  const thumbsData = chartPoints.map((point) => {
    return {
      label: point?.tooltipLabel ?? '',
      displayValue: point?.yDisplay ?? '',
      color: point?.color ?? ThemeColor.Primary,
      icon: point?.icon ?? IconType.Pipe,
    };
  });

  return (
    <Stack sx={(theme) => ({ paddingTop: theme.spacing(1) })}>
      <Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}>{data.title}</Typography>
      <Box
        sx={(theme) => ({
          margin: theme.spacing(4, 0, 1),
          paddingX: theme.spacing(2),
          [theme.breakpoints.up('md')]: { width: '500px' },
        })}
      >
        <Slider
          sx={(theme) => ({
            '.MuiSlider-rail': {
              color: theme.palette.grey[900],
              height: theme.spacing(0.2),
            },
            '.MuiSlider-markLabel': {
              color: theme.palette.common.black,
              '&.primary-color-label': {
                color: theme.palette.primary.main,
              },
              '&.secondary-color-label': {
                color: theme.palette.secondary.main,
              },
              '&.info-color-label': {
                color: theme.palette.info.main,
              },
            },
            '.MuiSlider-thumb': {
              backgroundColor: theme.palette.grey[900],
              '&.pipe-thumb': {
                borderRadius: 0,
                width: '2px',
              },
              '&.primary-color-thumb': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              },
              '&.secondary-color-thumb': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
              },
              '&.info-color-thumb': {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.main,
              },
            },
          })}
          track={false}
          orientation="horizontal"
          min={minVal}
          max={maxVal}
          defaultValue={sliderValues}
          getAriaLabel={(index) => {
            return sliderLabels[index];
          }}
          slots={{ thumb: AvgMaxMinThumb, markLabel: AvgMaxMinMark }}
          slotProps={{
            markLabel: { thumbsdata: thumbsData } as any,
            thumb: { thumbsdata: thumbsData } as any,
          }}
          marks={marks}
          disabled
        />
      </Box>
      <Typography sx={(theme) => ({ color: theme.palette.grey[600] })}>{data.subTitle}</Typography>
    </Stack>
  );
};

export default AvgMaxMinChartType;
