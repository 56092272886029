import { Skeleton, Box } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import useIsViewport from 'hooks/useIsViewport';
import { ChartData, ChartSeries, ChartType } from 'generated/graphql';
import AvgMaxMinChartType from './AvgMaxMinChartType';
import BarChartType from './BarChartType';
import { ChartVariant, MOBILE_CHART_HEIGHT, CHART_HEIGHT } from './ChartFunctions';
import LineChartType from './LineChartType';
import PieChartType from './PieChartType';

const Chart = ({
  data,
  variant = ChartVariant.DEFAULT,
  isStacked = false,
}: {
  data?: ChartData;
  variant?: ChartVariant;
  isStacked?: boolean;
}) => {
  const [chartPeriodIndex, setChartPeriodIndex] = useState<number>(0);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);

  const [offsetWidth, setOffsetWidth] = useState(null);
  const isMobile = useIsViewport('md');
  const chartHeight = isMobile ? MOBILE_CHART_HEIGHT : CHART_HEIGHT;

  const elementRef = useRef(null);
  useEffect(() => {
    setChartPeriodIndex(0);
  }, [data]);

  // useEffect to set the offsetWidth once it's available
  useEffect(() => {
    const currentElement = elementRef?.current as any;
    if (currentElement) {
      setOffsetWidth(currentElement.offsetWidth);
    }
  }, [elementRef]);

  const selectedPeriod = data?.periods[chartPeriodIndex];
  const validSeries = selectedPeriod?.multiSeries
    ? selectedPeriod?.multiSeries
        .filter((chartSeries: ChartSeries) => chartSeries.data && chartSeries.data.length > 0)
        .map((current: ChartSeries) => ({
          name: current.title,
          data: current.data ?? [],
        }))
    : [];

  const handleLegendSelect = (e: any) => {
    const id = e.payload?.id ?? e.id;
    if (hiddenSeries.includes(id)) {
      setHiddenSeries((prev) => prev.filter((series) => series !== id));
    } else {
      const updatedHiddenSeries = [...hiddenSeries];
      updatedHiddenSeries.push(id);
      setHiddenSeries(updatedHiddenSeries);
    }
  };

  const chartType = (data as any)?.chartType ?? data?.type; // @todo: revisit, and ask BE to update TableType! [TableType!]! to match chartType alias was given to chart to prevent union from breaking
  return (
    <Box style={{ width: '100%', height: 'fit-content' }}>
      {selectedPeriod ? (
        <Box ref={elementRef} sx={{ width: '100%', height: '100%', top: 0, left: 0 }}>
          {chartType === ChartType.Bar ? (
            <BarChartType
              validSeries={validSeries}
              variant={variant}
              isStacked={isStacked}
              subText={data.subText}
              axisLabelX={data.axisLabelX}
              axisLabelY={data.axisLabelY}
              layout={(data?.layout?.length ?? 0) > 0 ? data.layout : undefined}
            />
          ) : chartType === ChartType.Pie ? (
            offsetWidth !== null && (
              <PieChartType
                subTitle={data.subTitle}
                subText={data.subText}
                validSeries={validSeries}
                variant={variant}
                width={(elementRef?.current as any)?.offsetWidth ?? chartHeight}
                height={chartHeight}
                hiddenSeries={hiddenSeries}
                onLegendSelect={handleLegendSelect}
              />
            )
          ) : chartType === ChartType.Line ? (
            <LineChartType
              validSeries={validSeries}
              hiddenSeries={hiddenSeries}
              variant={variant}
              height={chartHeight}
              onLegendSelect={handleLegendSelect}
            />
          ) : chartType === ChartType.AvgMaxMin ? (
            <AvgMaxMinChartType data={data} />
          ) : null}
        </Box>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={chartHeight} />
      )}
    </Box>
  );
};

export default Chart;
