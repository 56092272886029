import { Box, CircularProgress, InputAdornment, TextField, SxProps, Card, Stack, Chip } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdSearch, MdClose, MdFilterList, MdCalendarToday, MdCancel } from 'react-icons/md';
import IconButton from 'components/IconButton';
import { BaseTheme } from 'config/base-theme';
import { useAnalytics } from 'hooks/useAnalytics';
import useIsDarkMode from 'hooks/useIsDarkMode';
import useIsViewport from 'hooks/useIsViewport';
import Chips from './Chips';
import HelpActions from './HelpActions';
import { useWhiteLabelling } from './WhiteLabellingProvider';

const chipContainerStyle = (theme: BaseTheme) => ({
  padding: theme.spacing(1),
  display: 'flex',
  borderRadius: theme.spacing(0, 0, 3, 3),
});

export enum SearchBarVariant {
  GENERAL = 'General',
  GREYED = 'Greyed',
  ROUNDED = 'Rounded',
}

export const DEFAULT_SEARCHBAR_WIDTH = '280px';
const DEFAULT_SEARCHBAR_HEIGHT = '34px';
const DEFAULT_SEARCHBAR_HEIGHT_MOBILE = '40px';

interface SearchActionProps {
  variant: SearchAction;
  onClick: () => void;
  color?: 'primary' | 'secondary' | 'info';
  chipLabel?: string;
  onClear?: () => void;
}

export interface SearchBarFieldProps {
  value?: string;
  loading?: boolean;
  placeholder?: string;
  variant?: SearchBarVariant;
  showClear?: boolean;
  inputProps?: object;
  action?: SearchActionProps;
  sxProps?: object;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (text: string) => void;
  onClose?: () => void;
}

interface ChipsInterface {
  data: string[];
  onClose?: () => void;
  onClick?: (value: string) => void;
  setSearch?: boolean;
}

interface SearchBarChipsProps extends SearchBarFieldProps {
  expanded?: boolean;
  chips?: ChipsInterface;
  onSetExpanded?: (value: boolean) => void;
}

const chipFieldExpandedStyle = {
  '& .MuiInputBase-root.MuiOutlinedInput-root fieldset': {
    border: 'none',
    borderRadius: 0,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
};

const getSearchBarStyle = (theme: BaseTheme, variant: SearchBarVariant, sxProps = {}) => {
  switch (variant) {
    case SearchBarVariant.GREYED:
      return {
        backgroundColor: theme.palette.common.white,
        ...sxProps,
      };
    case SearchBarVariant.ROUNDED:
      return {
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.common.white,
          height: DEFAULT_SEARCHBAR_HEIGHT,
          minWidth: DEFAULT_SEARCHBAR_WIDTH,
          borderRadius: theme.spacing(3),
          [theme.breakpoints.down('md')]: {
            height: DEFAULT_SEARCHBAR_HEIGHT_MOBILE,
          },
        },
        ...sxProps,
      };
    default:
      return {
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.common.white,
          height: DEFAULT_SEARCHBAR_HEIGHT,
          minWidth: DEFAULT_SEARCHBAR_WIDTH,
          maxWidth: DEFAULT_SEARCHBAR_WIDTH,
          [theme.breakpoints.down('md')]: {
            height: DEFAULT_SEARCHBAR_HEIGHT_MOBILE,
          },
        },
        ...sxProps,
      };
  }
};

const SearchBarChips = ({
  chips,
  expanded = false,
  onChange = () => {},
  onSetExpanded,
  ...rest
}: SearchBarChipsProps) => {
  const [selectedChip, setSelectedChip] = useState('');
  const [expandSection, setExpandSection] = useState(expanded);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setExpandSection(expanded);
  }, [expanded]);

  const handleChipSelected = useCallback(
    (value: string) => {
      setSelectedChip(value);
      chips?.setSearch && onChange(value);
      setExpandSection(false);
      onSetExpanded && onSetExpanded(false);
      chips?.onClick && chips.onClick(value);
    },
    [setSelectedChip, onChange, onSetExpanded, chips],
  );

  return (
    <Box sx={{ width: '100%' }} ref={containerRef}>
      <SearchBarField
        onChange={onChange}
        onFocus={() => {
          if (chips?.data && chips.data.length > 0) {
            setExpandSection(true);
            onSetExpanded && onSetExpanded(true);
          }
        }}
        sxProps={expanded ? chipFieldExpandedStyle : {}}
        {...rest}
      />
      {expandSection && chips?.data && chips?.data.length > 0 && (
        <Box
          id="searchChipsBox"
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            position: 'absolute',
            width: containerRef.current?.clientWidth ?? DEFAULT_SEARCHBAR_WIDTH,
            borderRadius: 1,
            overflow: 'hidden',
            zIndex: 10,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          })}
        >
          <Chips
            data={chips?.data}
            onChange={handleChipSelected}
            value={selectedChip}
            sx={chipContainerStyle}
            chipSx={{
              height: '28px',
            }}
            selectedColor="warning"
            showAll
          />
        </Box>
      )}
    </Box>
  );
};

const SearchBarField = ({
  value,
  loading = false,
  placeholder = 'Search...',
  variant = SearchBarVariant.GENERAL,
  showClear,
  action,
  onFocus,
  onBlur,
  onChange,
  sxProps,
}: SearchBarFieldProps) => {
  const isMobile = useIsViewport('md');
  const [actionClicked, setActionClicked] = useState(false);
  const isDarkMode = useIsDarkMode();
  const { logAnalytics } = useAnalytics();

  const inputProps = action
    ? {
        endAdornment: (
          <IconButton
            size="small"
            aria-label="close"
            color={action.color}
            onClick={() => {
              action.onClick();
              setActionClicked(!actionClicked);
            }}
          >
            {action.variant ? SearchActionIcons[action.variant] : <></>}
          </IconButton>
        ),
      }
    : {};

  if (value === undefined || onChange === undefined) {
    return null;
  }
  return (
    <Stack direction="row">
      <TextField
        variant="outlined"
        fullWidth
        placeholder={action?.chipLabel ? '' : placeholder}
        value={value}
        color="primary"
        sx={(theme) => getSearchBarStyle(theme, variant, sxProps)}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          onChange(e.target.value);
          if (e.target.value.trim().length > 0) {
            logAnalytics('search', { value: e.target.value });
          }
        }}
        slotProps={{
          input: {
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                {loading ? (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    sx={(theme) => ({
                      maxHeight: theme.spacing(2.5),
                      maxWidth: theme.spacing(2.5),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    })}
                  />
                ) : (
                  <>
                    <MdSearch size={20} />
                    {action?.chipLabel && (
                      <Chip
                        size="small"
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightRegular,
                          marginLeft: theme.spacing(0.5),
                        })}
                        label={action?.chipLabel}
                        variant="filled"
                        color="primary"
                        onClick={() => {
                          action?.onClick();
                          setActionClicked(!actionClicked);
                        }}
                        deleteIcon={<MdCancel size={16} />}
                        onDelete={action?.onClear}
                      />
                    )}
                  </>
                )}
              </InputAdornment>
            ),
            ...inputProps,
          },
        }}
      />
      {showClear && isMobile && (
        <IconButton
          onClick={() => onChange('')}
          sx={(theme) => ({ color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main })}
        >
          <MdClose size={20} />
        </IconButton>
      )}
    </Stack>
  );
};

export interface SearchbarProps {
  value?: string;
  placeholder?: string;
  onChange?: (text: string) => void;
  onClose?: () => void;
  chips?: ChipsInterface;
  loading?: boolean;
  action?: SearchActionProps;
  showHelp?: boolean;
  showClear?: boolean;
  sx?: SxProps<BaseTheme>;
  variant?: SearchBarVariant;
  alert?: string;
}

export enum SearchAction {
  Filter = 'Filter',
  DateRange = 'DateRange',
}

const SearchActionIcons = {
  [SearchAction.Filter]: <MdFilterList size={20} />,
  [SearchAction.DateRange]: <MdCalendarToday size={20} />,
};

const SearchBar = ({ onClose, chips, action, showHelp = false, sx = {}, alert, ...rest }: SearchbarProps) => {
  const isMobile = useIsViewport('md');
  const [expandSection, setExpandSection] = useState(false);
  const { isFreshmarkProfile } = useWhiteLabelling();

  useEffect(() => {
    //When clicked outside of searchbar then close
    const handleClick = (event: MouseEvent) => {
      const searchBarElement = document.getElementById('searchBarContainer');
      const searchChipsElement = document.getElementById('searchChipsBox');

      if (
        searchBarElement &&
        !searchBarElement.contains(event.target as Element) &&
        (!searchChipsElement || (searchChipsElement && !searchChipsElement.contains(event.target as Element)))
      ) {
        if (chips?.onClose) {
          chips.onClose();
        }
        onClose && onClose();
        setExpandSection(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [chips, onClose]);

  if (alert) {
    return <Card role={'alert'}>{alert}</Card>;
  }
  return (
    <Stack sx={sx} id="searchBarContainer">
      <SearchBarChips
        onClose={() => {}}
        expanded={expandSection}
        onSetExpanded={setExpandSection}
        chips={chips}
        action={
          action && {
            ...action,
            color: action.color ?? 'primary',
          }
        }
        {...rest}
      />
      {isMobile && showHelp && isFreshmarkProfile && (
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
          })}
        >
          <HelpActions />
        </Box>
      )}
    </Stack>
  );
};

export default SearchBar;
