import { Table, TableContainer, TableFooter, TableRow, LinearProgress, Box, Typography, Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { ConfirmDialogVariant, FormDialogState } from 'components/FormDialog';
import LazyFormDialog from 'components/LazyFormDialog';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import useIsViewport from 'hooks/useIsViewport';
import {
  ExpandableTableRow,
  TableCell as TableCellData,
  ExpandableTable as ExpandableTableType,
  TitleVariant,
  TableType,
} from 'generated/graphql';
import ExpandableTableActionBar from './ExpandableTableActionBar';
import ExpandableTableGroup from './ExpandableTableGroup';
import ExpandableTableHead from './ExpandableTableHead';
import ExpandableTablePending from './ExpandableTablePending';
import { getTableContainerStyle, getTableStyle } from './ExpandableTableStyles';
import ExpandableTableTotals from './ExpandableTableTotals';

export interface TableRowData extends ExpandableTableRow {
  data?: any;
}

export interface TableRowGroup {
  rows: TableRowData[];
  total: string;
  totals?: TableCellData[];
}

interface TableProps {
  table: ExpandableTableType;
  isFetching?: boolean;
  hasPending?: boolean;
  footer?: ReactNode;
  noBorder?: boolean;
  showTitle?: boolean;
  onRefresh?: () => void;
  onActionClick?(actionCode: string, rowIds: string[]): void;
  onFieldUpdate?(rowId: string, cellIndex: number, newValue: string): void;
  onCustomSchemeRequest?(url: string): void;
  setShowGraphs?(showGraph: boolean): void;
  setHasScroll?(hasScroll: boolean): void;
}

const ExpandableTableNew = ({
  table,
  isFetching = false,
  hasPending = false,
  footer,
  noBorder = false,
  showTitle = false,
  onRefresh = () => {},
  onActionClick = () => {},
  onFieldUpdate = () => {},
  onCustomSchemeRequest = () => {},
  setShowGraphs = () => {},
  setHasScroll = () => {},
}: TableProps) => {
  const isMobile = useIsViewport('md');

  const { columns, groups, tableActions, totals, type } = table;

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);

  const handleFormSubmit = () => {
    setFormDialog(defaultFormDialogState);
    onRefresh();
  };

  useEffect(() => {
    const tableContainerId = document.getElementById('tableContainer');
    if (
      tableContainerId &&
      tableContainerId.parentElement &&
      tableContainerId?.scrollWidth > tableContainerId?.parentElement?.offsetWidth &&
      !isMobile
    ) {
      setHasScroll(true);
    } else {
      setHasScroll(false);
    }
  }, [isMobile, setHasScroll]);

  if (!table) return null;

  const showTopRow = showTitle || hasPending || selectedRows.length > 0 || tableActions?.length > 0;

  return (
    <>
      {showTopRow && (
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={(theme) => ({
            marginY: theme.spacing(1),
            [theme.breakpoints.down('md')]: {
              marginX: table.type === TableType.Basic ? theme.spacing(2) : 'inherit',
            },
          })}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontWeight: [TitleVariant.Bold, TitleVariant.Highlighted].includes(table.variant)
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
              color:
                table.variant === TitleVariant.Highlighted ? theme.palette.success.main : theme.palette.text.primary,
            })}
          >
            {showTitle ? table.title : ''}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {hasPending && <ExpandableTablePending />}
            <ExpandableTableActionBar
              actions={tableActions}
              disabled={hasPending}
              selectedRowIds={selectedRows}
              onActionClick={onActionClick}
              setFormDialog={setFormDialog}
            />
          </Box>
        </Stack>
      )}
      <TableContainer id={'tableContainer'} sx={{ ...getTableContainerStyle(type) }}>
        {isFetching && <LinearProgress color="secondary" />}
        <Table
          sx={{
            border: noBorder ? 'none' : '', // TODO: Need to remove noBorder
            ...getTableStyle(type),
          }}
        >
          <ExpandableTableHead
            type={type}
            columns={columns}
            tableActions={tableActions}
            onTagClick={(url: string) => handleSchemeOrQuery(url, '', { setFormDialog: setFormDialog })}
          />
          {groups?.map((group, i) => {
            return (
              <ExpandableTableGroup
                key={i}
                groups={groups}
                group={group}
                index={i}
                isFetching={isFetching}
                hasPending={hasPending}
                columns={columns}
                type={type}
                footer={footer}
                actions={tableActions}
                refresh={onRefresh}
                onFieldUpdate={onFieldUpdate}
                onCustomSchemeRequest={onCustomSchemeRequest}
                setShowGraphs={setShowGraphs}
                setSelectedRows={setSelectedRows}
              />
            );
          })}

          <TableFooter>
            <ExpandableTableTotals totals={totals} />
            <TableRow sx={(theme) => ({ width: '100%', background: theme.palette.background.paper })}>
              {footer}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        eTags={formDialog.eTags}
        open={formDialog.open}
        confirmDialogVariant={ConfirmDialogVariant.Default}
        onSubmit={handleFormSubmit}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
      />
    </>
  );
};

export default ExpandableTableNew;
