import { Box, Collapse, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode, Suspense, useState } from 'react';
import { MdFilterList } from 'react-icons/md';
import Chart from 'components/Charts/Chart';
import { ChartVariant } from 'components/Charts/ChartFunctions';
import useIsViewport from 'hooks/useIsViewport';
import { ChartData } from 'generated/graphql';
import ChartContainerEmptyState from './ChartContainerEmptyState';
import TooltipDialog from './Tooltip';

const ChartContainer = ({
  title = '',
  charts,
  disableCharts = false,
  quickViewFilter,
  isStacked = false,
  backgroundColor,
  toolTip,
  fetching,
}: {
  title?: string;
  charts?: ChartData[];
  disableCharts?: boolean;
  quickViewFilter?: ReactNode;
  isStacked?: boolean;
  backgroundColor?: string;
  toolTip?: string;
  fetching?: boolean;
}) => {
  const isMobile = useIsViewport('sm');
  const [viewQuickFilter, setViewQuickFilter] = useState(false);
  const hasCharts = charts && charts.length > 0 && !disableCharts;
  return (
    <Box
      id="chartContainer"
      sx={(theme) => ({
        padding: isMobile ? 1 : 2,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.spacing(1),
        backgroundColor: backgroundColor ?? 'initial',
      })}
    >
      {hasCharts && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={!charts || charts.length === 0 ? 'center' : 'space-between'}
          spacing={1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              sx={(theme) => ({
                color: theme.palette.grey[800],
                fontSize: theme.spacing(2),
                fontWeight: theme.typography.fontWeightMedium,
              })}
            >
              {title}
            </Typography>
            {toolTip && <TooltipDialog title={title} info={toolTip} />}
          </Stack>
          {!isMobile && quickViewFilter && <>{quickViewFilter}</>}
          {isMobile && quickViewFilter && (
            <IconButton
              color="primary"
              sx={(theme) => ({ background: theme.palette.primary.light + '40' })}
              onClick={() => setViewQuickFilter(!viewQuickFilter)}
            >
              <MdFilterList size={20} />
            </IconButton>
          )}
        </Stack>
      )}

      {isMobile && hasCharts && quickViewFilter && (
        <Collapse in={viewQuickFilter} collapsedSize={0} sx={{ marginBottom: 2 }}>
          {quickViewFilter}
        </Collapse>
      )}
      <Suspense fallback={null}>
        <Box id={hasCharts ? 'chartSection' : 'emptyChartSection'}>
          {fetching ? (
            <Skeleton variant="rounded" width="100%" height="100px" sx={(theme) => ({ marginTop: theme.spacing(2) })} />
          ) : hasCharts ? (
            charts.map((chart, chartIndex) => {
              return (
                <>
                  <Chart data={chart} variant={ChartVariant.LIGHT} isStacked={isStacked} />
                  {chartIndex !== charts.length - 1 && <Divider sx={(theme) => ({ paddingTop: theme.spacing(1) })} />}
                </>
              );
            })
          ) : (
            <ChartContainerEmptyState />
          )}
        </Box>
      </Suspense>
    </Box>
  );
};

export default ChartContainer;
