import { Button, DialogTitle, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { FaClipboardList } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const ACCEPTED_FILE_TYPES = 'image/*, application/pdf';

const ImageUploadDialogTitle = ({
  files,
  isEditor,
  loading,
  onClose,
  onFileUpload,
}: {
  files: string[];
  isEditor: boolean;
  loading: boolean;
  onClose: () => void;
  onFileUpload: (event: any) => Promise<void>;
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  };

  return (
    <DialogTitle>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6" sx={(theme) => ({ color: theme.palette.primary.main })}>
          {isEditor ? 'Edit uploads' : 'Upload from device'}
        </Typography>
        <IconButton onClick={onClose}>
          <MdClose size={24} fill={theme.palette.grey[700]} />
        </IconButton>
      </Stack>
      <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[700] })}>
        Upload your documents from your files or camera roll. Alternatively, take a picture of your document.
      </Typography>
      <input
        accept={ACCEPTED_FILE_TYPES}
        type="file"
        onChange={onFileUpload}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <Button
        variant="outlined"
        color="primary"
        disabled={loading}
        onClick={() => handleUploadClick()}
        sx={{ width: '100%', marginY: theme.spacing(1) }}
        startIcon={<FaClipboardList />}
      >
        Upload file
      </Button>
      {(loading || files.length > 0) && <Divider sx={(theme) => ({ margin: theme.spacing(1, 0, 2) })} />}
    </DialogTitle>
  );
};

export default ImageUploadDialogTitle;
