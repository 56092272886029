import { AnalyticsCallOptions, logEvent } from 'firebase/analytics';
import { getGoogleAnalytics } from 'services/firebase';

export const logAnalytics = async (
  eventName: string,
  eventParams?:
    | {
        [key: string]: any;
        search_term?: string | undefined;
      }
    | undefined,
  options?: AnalyticsCallOptions | undefined,
  groupId: string = 'other_other',
) => {
  const analytics = await getGoogleAnalytics();
  if (analytics) {
    const updatedEventParams = {
      ...eventParams,
      group_id: groupId,
    };
    logEvent(analytics, eventName, updatedEventParams, options);
  }
};
