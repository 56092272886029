import { getIdTokenResult, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { AuthUser, UserClaims, UserStatus, UserType } from 'interfaces/user';
import {
  useContext,
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { logout, resetToken, resetStorage } from 'services/auth';
import { User, auth } from 'services/firebase';
import { logError } from 'services/logging';
import { NILE_ADMIN_PROFILE_ID } from 'services/theming';
import { useGraphState } from 'components/GraphProvider';
import { Role } from 'generated/graphql';
import { useSnackbar } from './useSnackBar';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const maxRetries = 10;
const retryDelay = 500;

export const getUserFromParsedIdToken = (parsedToken: any): AuthUser => {
  const claims = parsedToken?.claims as UserClaims;

  return {
    name: claims.name,
    email: claims.email,
    userType: claims.userType,
    profileId: claims.profileId,
    adminProfileId: claims.admin_profile_id ?? NILE_ADMIN_PROFILE_ID,
    hubId: claims.hubId,
    userId: claims.user_id,
    theme: claims.theme,
    status: claims.status,
    intercomUserHash: claims.intercomUserHash,
    multiProfile: claims.multiProfile,
    roles: claims.roles,
    emailVerified: claims.email_verified,
  };
};

export interface IUser {
  user: AuthUser | null;
  setUser: Dispatch<SetStateAction<AuthUser | null>>;
  isAdmin: boolean;
  isNileUser: boolean;
  isSeller: boolean;
  isBuyer: boolean;
  isRegistering: boolean;
  isInitialised: boolean;
  isPendingReview: boolean;
  onLogout: () => Promise<void>;
  onRefreshUser: () => Promise<AuthUser | undefined>;
  hasRole: (role: Role) => boolean;
  onValidateUser: () => Promise<void>;
}

export const UserContext = createContext<null | IUser>(null);

export const UserProvider = ({ children, initial = null }: { children: ReactNode; initial?: null | AuthUser }) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [user, setUser] = useState<null | AuthUser>(initial);
  const [isOnline, setOnline] = useState(navigator.onLine);
  const { resetClient } = useGraphState();
  const isNileUser = user?.adminProfileId === NILE_ADMIN_PROFILE_ID;
  const isRegistering = user?.status === undefined || user?.status === UserStatus.Registering;
  const isPendingReview = !isRegistering && user?.status !== UserStatus.Approved;
  const { showSnackbar } = useSnackbar();

  const onTokenChange = useCallback(
    async (tokenUser: User | null) => {
      if (tokenUser) {
        const result = await getIdTokenResult(tokenUser);
        setUser(getUserFromParsedIdToken(result));
      } else {
        resetToken();
        resetStorage();
        resetClient();
        setUser(null);
      }
      setIsInitialised(true);
    },
    [setUser, resetClient],
  );

  const handleLogout = useCallback(async () => {
    await logout(true, showSnackbar);
  }, [showSnackbar]);

  const handleRefreshUser = useCallback(async () => {
    try {
      if (!auth.currentUser) return;
      const authUser = await getIdTokenResult(auth.currentUser, isOnline);
      const parsedUser = getUserFromParsedIdToken(authUser);
      setUser(parsedUser);
      return parsedUser;
    } catch (err: any) {
      logError(err);
    }
  }, [isOnline]);

  const handleValidateUser = useCallback(async () => {
    if (!isOnline) {
      // validation is only on Google signup/signin so no need to do when offline
      throw new Error('Please connect to the internet to validate your account.');
    }
    for (let retries = 0; retries < maxRetries; retries++) {
      try {
        await handleRefreshUser();
        if (user?.status === 'Rejected') {
          throw new Error('Your profile is not active. Please contact support for more information.');
        }
        if (user?.status !== undefined) {
          return;
        }
        if (retries < maxRetries - 1) {
          await delay(retryDelay);
        }
      } catch (error: any) {
        logError(error, { context: 'validateUser', message: error?.message });
        throw error;
      }
    }
    throw new Error('Failed to retrieve user status after multiple attempts. Please check your internet connection.');
  }, [isOnline, handleRefreshUser, user]);

  useEffect(() => {
    onAuthStateChanged(auth, onTokenChange);
    onIdTokenChanged(auth, onTokenChange);
  }, [onTokenChange]);

  useEffect(() => {
    window.addEventListener('focus', handleRefreshUser);
    window.addEventListener('visibilitychange', handleRefreshUser);
    return () => {
      window.removeEventListener('focus', handleRefreshUser);
      window.removeEventListener('visibilitychange', handleRefreshUser);
    };
  }, [handleRefreshUser]);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const value = useMemo(() => {
    const hasRole = (role: Role) => !!user?.roles?.includes(role);

    return {
      user,
      setUser,
      isRegistering,
      isPendingReview,
      isAdmin: user?.userType === UserType.Admin,
      isSeller: user?.userType === UserType.Seller,
      isBuyer: user?.userType === UserType.Buyer,
      isNileUser,
      onLogout: handleLogout,
      onRefreshUser: handleRefreshUser,
      isInitialised,
      hasRole,
      onValidateUser: handleValidateUser,
    };
  }, [
    user,
    setUser,
    handleLogout,
    isRegistering,
    isPendingReview,
    handleRefreshUser,
    isNileUser,
    isInitialised,
    handleValidateUser,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const result = useContext(UserContext);
  if (!result) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return result;
};

export default useUser;
