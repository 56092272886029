import { ButtonProps, Stack } from '@mui/material';
import { getPrefixedRoute } from 'services/theming';
import Button from 'components/Button';
import { FormDialogState } from 'components/FormDialog';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { TableAction, TableType } from 'generated/graphql';

const ExpandableTableActionBar = ({
  type,
  disabled = false,
  actions,
  selectedRowIds,
  onActionClick,
  setFormDialog,
}: {
  type?: TableType;
  disabled: boolean;
  actions: TableAction[];
  selectedRowIds: string[];
  onActionClick: (code: string, rowIds: string[]) => void;
  setFormDialog?: (value: FormDialogState) => void;
}) => {
  const { isEnabled } = useFeatures();
  const isProductRequestEnabled = isEnabled('ProductRequest');
  const isBasicType = type === TableType.Basic;

  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery();
  if (!actions.length) return null;
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          paddingX: isBasicType ? theme.spacing(1) : 0,
        },
      })}
    >
      {actions.map((action, i) => (
        <Button
          key={action.code}
          size="small"
          color={(action.button.color?.toLowerCase() as ButtonProps['color']) || 'primary'}
          variant={(action.button.variant?.toLowerCase() as ButtonProps['variant']) || 'outlined'}
          disabled={selectedRowIds.length < action.minRequiredRowSelection || (disabled && i !== 0)}
          href={
            !isProductRequestEnabled && action.button.actions?.[0]?.url
              ? getPrefixedRoute(action.button.actions[0].url)
              : undefined
          }
          onClick={() => {
            if (isProductRequestEnabled && action.button.actions?.[0]?.url) {
              handleSchemeOrQuery(action.button.actions?.[0]?.url ?? '', '', { setFormDialog: setFormDialog });
            } else onActionClick(action.code, selectedRowIds);
          }}
        >
          {action.label}
        </Button>
      ))}
    </Stack>
  );
};

export default ExpandableTableActionBar;
