import { ThemeVariant, getThemeVariant } from 'services/theming';
import beans from './Beans.svg';
import fennel from './Fennel.svg';
import kale from './Kale.svg';
import pakchoi from './Pakchoi.svg';
import animalFeed from './animal-feed.svg';
import apple from './apple.svg';
import artichoke from './artichoke.svg';
import asparagus from './asparagus.svg';
import avocado from './avocado.svg';
import banana from './banana.svg';
import beetroot from './beetroot.svg';
import berries from './berries.svg';
import blackberry from './blackberry.svg';
import blueberry from './blueberry.svg';
import bringle from './bringle.svg';
import broccoli from './broccoli.svg';
import butternut from './butternut.svg';
import cabbage from './cabbage.svg';
import carrot from './carrot.svg';
import cauliflower from './cauliflower.svg';
import celery from './celery.svg';
import cherry from './cherry.svg';
import chilli from './chilli.svg';
import coconut from './coconut.svg';
import corn from './corn.svg';
import cucumber from './cucumber.svg';
import dragonFruit from './dragonfruit.svg';
import equipment from './equipment.svg';
import expertAdvice from './expert-advice.svg';
import fertilizer from './fertilizer.svg';
import fig from './fig.svg';
import fire from './fire.svg';
import flower from './flower.svg';
import garlic from './garlic.svg';
import generalGoods from './general-goods.svg';
import ginger from './ginger.svg';
import goggles from './goggles.svg';
import gooseberry from './gooseberry.svg';
import grapes from './grapes.svg';
import greenbean from './greenbean.svg';
import greenhouse from './greenhouse.svg';
import guava from './guava.svg';
import herb from './herb.svg';
import irrigation from './irrigation.svg';
import kiwi from './kiwi.svg';
import leeks from './leek.svg';
import lemon from './lemon.svg';
import lime from './lime.svg';
import litchi from './litchi.svg';
import livestock from './livestock.svg';
import mango from './mango.svg';
import mushroom from './mushroom.svg';
import muskmelon from './muskmelon.svg';
import okra from './okra.svg';
import onion from './onion.svg';
import onionAndPotato from './onionAndPotato.svg';
import orange from './orange.svg';
import packagingMaterial from './packaging-material.svg';
import papaya from './papaya.svg';
import passionFruit from './passion_fruit.svg';
import peach from './peach.svg';
import pear from './pear.svg';
import pepper from './pepper.svg';
import persimmon from './persimmons.svg';
import pillowPack from './pillow-pack.svg';
import pineapple from './pineapple.svg';
import plantNutrition from './plant-nutrition.svg';
import plum from './plum.svg';
import pomegranate from './pomegranate.svg';
import potato from './potato.svg';
import powerProducts from './power-products.svg';
import pumpkin from './pumpkin.svg';
import radish from './radish.svg';
import raspberry from './raspberry.svg';
import seed from './seed.svg';
import rice from './sheaf-of-rice.svg';
import shoe from './shoe.svg';
import soilAnalysis from './soil-analysis.svg';
import soup from './soup.svg';
import spinach from './spinach.svg';
import springOnion from './spring-onion.svg';
import strawberry from './strawberry.svg';
import tomato from './tomato.svg';
import turnipIcon from './turnip-icon.svg';
import valueAdded from './value-added.svg';
import watermelon from './watermelon.svg';
import welder from './welder.svg';
import workwear from './workwear.svg';

export const getIconSrc = (inputSku: string) => {
  const sku = inputSku.toLowerCase();

  const theme = getThemeVariant();
  // TODO: Removed villa theme check for demo. Re-add when required by villa launch and icons reviewed
  if ([ThemeVariant.Inputs].includes(theme)) {
    if (sku.includes('soil')) return soilAnalysis;
    if (sku.includes('power products')) return powerProducts;
    if (sku.includes('plant')) return plantNutrition;
    if (sku.includes('greenhouse')) return generalGoods;
    if (sku.includes('fertilisers')) return fertilizer;
    if (sku.includes('fire')) return fire;
    if (sku.includes('packaging')) return packagingMaterial;
    if (sku.includes('growth')) return greenhouse;
    if (sku.includes('goggles')) return goggles;
    if (sku.includes('equipment')) return equipment;
    if (sku.includes('expert')) return expertAdvice;
    if (sku.includes('ppe') && !sku.includes('pepper')) return workwear;
    if (sku.includes('irrigation')) return irrigation;
    if (sku.includes('animal')) return animalFeed;
    if (sku.includes('weld')) return welder;
    if (sku.includes('seed')) return seed;
    if (sku.includes('livestock')) return livestock;
  }

  if ([ThemeVariant.Nile, ThemeVariant.FreshMark].includes(theme)) {
    if (sku.includes('potatoes & onions')) return onionAndPotato;
    if (sku.includes('artichoke')) return artichoke;
    if (sku.includes('pine')) return pineapple;
    if (sku.includes('apple') || (sku.includes('appl') && !sku.includes('pine'))) return apple;
    if (sku.includes('gooseberries')) return gooseberry;
    if (sku.includes('dragfr') || sku.includes('dragon')) return dragonFruit;
    if (sku.includes('raspberries')) return raspberry;
    if (sku.includes('aspar') || sku.includes('bunches')) return asparagus;
    if (sku.includes('avo')) return avocado;
    if (sku.includes('mar') && !sku.includes('roses')) return cucumber;
    if (
      sku.includes('bana') ||
      (!['stone', 'other', 'grape'].some((current) => sku.includes(current)) && sku.includes('fruit'))
    )
      return banana;
    if (sku.includes('beans')) return beans;
    if (sku.includes('beet')) return beetroot;
    if (sku.includes('strawb')) return strawberry;
    if (sku.includes('blueb')) return blueberry;
    if (sku.includes('blackb')) return blackberry;
    if (sku.includes('berr')) return berries;
    if (sku.includes('brin')) return bringle;
    if (sku.includes('broc')) return broccoli;
    if (sku.includes('butt') && !sku.includes('button') && !sku.includes('lett') && !sku.includes('mushr'))
      return butternut;
    if (sku.includes('cabb')) return cabbage;
    if (sku.includes('carr')) return carrot;
    if (sku.includes('caul')) return cauliflower;
    if (sku.includes('cherr') && !sku.includes('tomat')) return cherry;
    if (sku.includes('chil')) return chilli;
    if (sku.includes('cit')) return orange;
    if (sku.includes('coconut')) return coconut;
    if (sku.includes('corn') || sku.includes('sweetcor')) return corn;
    if (sku.includes('cucum')) return cucumber;
    if (sku.includes('fennel')) return fennel;
    if (sku.includes('fig')) return fig;
    if (sku.includes('garl')) return garlic;
    if (sku.includes('ging')) return ginger;
    if (sku.includes('grapef')) return orange;
    if (sku.includes('grape') && !sku.includes('fruit')) return grapes;
    if ((sku.includes('green') && !sku.includes('pepp') && !sku.includes('mango')) || sku.includes('grbeans'))
      return greenbean;
    if (sku.includes('guava')) return guava;
    if (sku.includes('kiwi')) return kiwi;
    if (sku.includes('kale')) return kale;
    if (sku.includes('lettuce')) return cabbage;
    if (sku.includes('leek')) return leeks;
    if (sku.includes('turnip')) return turnipIcon;
    if (sku.includes('lemon')) return lemon;
    if (sku.includes('mango') || sku.includes('apricot') || sku.includes('mantom')) return mango;
    if (sku.includes('mush')) return mushroom;
    if ((sku.includes('melon') && !sku.includes('water')) || sku.includes('musk')) return muskmelon;
    if (sku.includes('onio')) return onion;
    if (sku.includes('oran') && !sku.includes('potato')) return orange;
    if (sku.includes('okra')) return okra;
    if (sku.includes('pak')) return pakchoi;
    if (sku.includes('papa')) return papaya;
    if (sku.includes('pear') && !sku.includes('pot') && !sku.includes('pomegranate') && !sku.includes('pearl'))
      return pear;
    if (sku.includes('peach') || sku.includes('necta') || sku.includes('stone')) return peach;
    if (sku.includes('pepp')) return pepper;
    if (sku.includes('persimmon')) return persimmon;
    if (sku.includes('pillow')) return pillowPack;
    if (sku.includes('plum') || sku.includes('stone') || sku.includes('prune')) return plum;
    if (
      ['potato', 'pototh', 'potmon', 'potsif', 'sweetpot', 'pother', 'potsou', 'pottys'].some((current) =>
        sku.includes(current),
      )
    )
      return potato;
    if (sku.includes('pomegranate') || sku.includes('fruit')) return pomegranate;
    if (sku.includes('pump')) return pumpkin;
    if (sku.includes('radish')) return radish;
    if (sku.includes('rice')) return rice;
    if (sku.includes('show')) return shoe;
    if (sku.includes('spina')) return spinach;
    if (sku.includes('soup')) return soup;
    if (
      sku.includes('squash') ||
      sku.includes('hubbard') ||
      sku.includes('gemsq') ||
      sku.includes('babgem') ||
      sku.includes('pattypa')
    )
      return pumpkin;
    if (sku.includes('springon')) return springOnion;
    if (sku.includes('tomato') || (sku.includes('tom') && !sku.includes('mantom'))) return tomato; // Note: Mantom used for mango
    if (sku.includes('waterm')) return watermelon;
    if (sku.includes('litch')) return litchi;
    if (sku.includes('celery') || sku.includes('tabcel')) return celery;
    if (sku.includes('lime')) return lime;
    if (sku.includes('flower') || sku.includes('floral') || sku.includes('rose') || sku.includes('bouq')) return flower;
    if (sku.includes('herb')) return herb;
    if (sku.includes('passion')) return passionFruit;
    if (sku.includes('veg') && sku.includes('other')) return asparagus;
    if (sku.includes('veg')) return pepper;
    if (['value', 'sliced & diced', 'juice', 'shots', 'tubs', 'slicdic'].some((current) => sku.includes(current)))
      return valueAdded;
  }

  return null;
};
