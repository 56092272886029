import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import BtnIcon from 'components/BtnIcon';
import Button from 'components/Button';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { SubTable as SubTableType, TableCellType } from 'generated/graphql';
import SubTableActionCard from '../SubTableActionCard/SubTableActionCard';
import SubTableRows from './SubTableRows';

const SubTable = ({
  data,
  eTag,
  identifier,
  isFetching = false,
  isAllDisabled = false,
  reset = false,
  onReset = () => {},
  onHide = () => {},
  onDisable = () => {},
  onRefresh = () => {},
}: {
  data: SubTableType;
  eTag: string;
  identifier: string;
  isFetching?: boolean;
  isAllDisabled?: boolean;
  reset?: boolean;
  onReset?: (reset: boolean) => void;
  onHide?: (hide: boolean) => void;
  onDisable?: (disable: boolean) => void;
  onRefresh?: () => void;
}) => {
  const hasItems = data.items.length > 0;
  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: hasItems ? 7 : 12 }}>
          <Table sx={(theme) => ({ backgroundColor: theme.palette.grey[100], borderRadius: theme.spacing(1) })}>
            <TableHead>
              <TableRow sx={{ breakInside: 'avoid' }}>
                {data?.columns?.map((col) => (
                  <TableCell
                    key={col.value}
                    align={col?.align.toLowerCase() as 'left' | 'right'}
                    sx={(theme) => ({ padding: theme.spacing(1) })}
                  >
                    {col.value}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => (
                <TableRow key={index} sx={{ breakInside: 'avoid' }}>
                  {row?.map((cell) =>
                    cell.type === TableCellType.Action ? (
                      <TableCell
                        sx={(theme) => ({
                          border: index === data.rows.length - 1 ? 'none' : 'auto',
                          padding: theme.spacing(1),
                        })}
                        key={cell.value}
                        align={cell?.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cell.buttons.map((button, j) => {
                          return (
                            <div key={j}>
                              {button.value || button.actions[0].value ? (
                                <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                                  <Button
                                    size="small"
                                    color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                    startIcon={<BtnIcon icon={button.icon} />}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const url = button.actions[0]?.url ?? '';
                                      const query = button.actions[0]?.query ?? '';
                                      handleSchemeOrQuery(url, query);
                                    }}
                                  >
                                    {button.value ?? button.actions[0].value}
                                  </Button>
                                </Box>
                              ) : (
                                <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                                  <IconButton
                                    size="small"
                                    color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const url = button.actions[0]?.url ?? '';
                                      const query = button.actions[0]?.query ?? '';
                                      handleSchemeOrQuery(url, query);
                                    }}
                                  >
                                    <BtnIcon icon={button.icon} />
                                  </IconButton>
                                </Box>
                              )}
                            </div>
                          );
                        })}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={(theme) => ({
                          border: index === data.rows.length - 1 ? 'none' : 'auto',
                          padding: theme.spacing(1),
                        })}
                        key={cell.value}
                        align={cell?.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cell.value}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))}
              <SubTableRows rows={data.expandableRows} />
            </TableBody>
          </Table>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }}>
          <SubTableActionCard
            buttons={data.buttons}
            items={data.items}
            eTag={eTag}
            identifier={identifier}
            isFetching={isFetching}
            isDisabled={isAllDisabled}
            reset={reset}
            refresh={onRefresh}
            onReset={() => onReset(false)}
            onHide={onHide}
            onDisable={onDisable}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SubTable;
