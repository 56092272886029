import { Stack, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionList from 'components/ActionList';
import { FormDialogState } from 'components/FormDialog';
import LazyFormDialog from 'components/LazyFormDialog';
import TableDialog from 'components/TableDialog';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import { BaseTheme } from 'config/base-theme';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import isCustomScheme from 'utils/is-custom-scheme';
import { Button as ButtonType, DetailItem } from 'generated/graphql';
import BankDepositDetailsDialog from '../ExpandableTable/BankDepositDetailsDialog';
import ActionCardButton from './ActionCardButton';
import RowDetailItem from './RowDetailItem';

const isSingleActionButton = (button: ButtonType) =>
  button.actions.length === 1 && (!button.actions[0].group || button.actions[0].group?.length === 0);

const boxStyle = (theme: BaseTheme) => ({
  borderCollapse: 'separate',
  background: theme.palette.grey[50],
  borderRadius: theme.spacing(1),
});

const SubTableActionCard = ({
  buttons,
  isDisabled,
  identifier,
  eTag = undefined,
  isFetching,
  reset = false,
  items,
  onHide,
  refresh,
  onReset,
  onDisable,
}: {
  buttons: ButtonType[];
  isDisabled: boolean;
  identifier: string;
  eTag?: string;
  isFetching: boolean;
  reset?: boolean;
  items: DetailItem[];
  onHide?: (hide: boolean) => void;
  refresh(): void;
  onReset: (reset: boolean) => void;
  onDisable?: (disable: boolean) => void;
}) => {
  const [buttonIndex, setButtonIndex] = useState<number>();
  const [hasRowUpdates, setHasRowUpdates] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState(new Array(buttons.length).fill(false));
  const [actionsOpen, setActionsOpen] = useState(new Array(buttons.length).fill(false));
  const { handleSchemeOrQuery, isLoading, paymentDetails, tableDialog } = useHandleCustomSchemeAndQuery(refresh);

  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const disableAll = isDisabled || (isFetching && hasRowUpdates);

  useEffect(() => {
    if (buttonIndex && buttonsLoading[buttonIndex] !== isLoading) {
      const buttonsLoadingCopy = [...buttonsLoading];
      buttonsLoadingCopy[buttonIndex] = isLoading;
      setButtonsLoading(buttonsLoadingCopy);

      if (!isLoading) {
        setButtonIndex(undefined);
      }
    }
  }, [isLoading, buttonIndex, buttonsLoading]);

  const handleButtonClick = async (event: React.ChangeEvent<{}>, button: ButtonType, buttonIndex: number) => {
    if (!button.actions.length) {
      return;
    }

    const hasSingleAction = isSingleActionButton(button);

    if (hasSingleAction) {
      const action = button.actions[0];
      const { url, query } = action;
      const result = await handleSchemeOrQuery(url, query, { setFormDialog: setFormDialog });
      if (result?.error) {
        onReset?.(true);
        return;
      }
      if (isCustomScheme(url)) {
        event.stopPropagation();
        setHasRowUpdates(true);
      }
      return;
    }

    const at = [...actionsOpen];
    at[buttonIndex] = true;
    setActionsOpen(at);
  };

  useEffect(() => {
    setHasRowUpdates(false);
  }, [buttons]);
  return (
    <>
      <Box
        sx={(theme) => ({
          ...boxStyle(theme),
          padding: theme.spacing(1, 0.5, 1, 0),
        })}
      >
        {items.map((item, i) => (
          <RowDetailItem data={item} key={`${identifier + i}`} />
        ))}

        <Stack direction="row" justifyContent="space-around" flexWrap="wrap" sx={{ marginX: 2 }}>
          {buttons.map((button, i) => {
            return (
              <Box key={`${button.value + i}`} sx={{ margin: 0.75, flex: 1, minWidth: '35%', maxWidth: 'auto' }}>
                <Box sx={{ position: 'relative' }}>
                  <ActionCardButton
                    isDisabled={buttonsLoading[i] || disableAll}
                    button={button}
                    isLoading={buttonsLoading[i]}
                    key={`${button.value + i}`}
                    reset={reset}
                    eTag={eTag}
                    identifier={identifier}
                    onClick={(e) => handleButtonClick(e, button, i)}
                    onDisable={onDisable}
                    onHide={onHide}
                    onReset={onReset}
                    isFetching={isFetching}
                  />
                </Box>
                <ActionList
                  open={actionsOpen[i]}
                  button={button}
                  onClose={(action) => {
                    const updatedActionsOpen = [...actionsOpen];
                    updatedActionsOpen[i] = false;
                    setActionsOpen(updatedActionsOpen);

                    if (!action) return;

                    console.log('setFormDialog');
                    handleSchemeOrQuery(action.url, action.query, { setFormDialog: setFormDialog });
                    setButtonIndex(i);
                  }}
                />
              </Box>
            );
          })}
        </Stack>

        {tableDialog.content && (
          <TableDialog
            data={tableDialog.content}
            onClose={() => {
              tableDialog.clear();
              refresh();
            }}
          />
        )}
        {paymentDetails.open && (
          <BankDepositDetailsDialog shipmentId={paymentDetails.id} onClose={paymentDetails.onClose} />
        )}
      </Box>
      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        open={formDialog.open}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
        onSubmit={() => {
          setFormDialog(defaultFormDialogState);
          refresh();
        }}
      />
    </>
  );
};

export default SubTableActionCard;
