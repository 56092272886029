import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import { Navigate } from 'react-router-dom';
import { getRoutePrefix } from 'services/theming';
import useUser from 'hooks/useUser';

const Landing = lazy(() => import('screens/Auth/Landing'));

const HomeRedirect = () => {
  const { isBuyer, user } = useUser();
  const prefix = getRoutePrefix();

  if (!user?.status) {
    return <Landing />;
  }
  if (isBuyer) {
    return <Navigate to={`${prefix}/market`} replace />;
  }
  return <Navigate to={`${prefix}/shipments`} replace />;
};

export default HomeRedirect;
