import { DialogTitle, DialogContent, Box, Typography, Dialog } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPrefixedRoute } from 'services/theming';
import { useClient } from 'urql';
import { SnackbarVariant, useSnackbar } from 'hooks/useSnackBar';
import { TableDialogFragment } from 'generated/graphql';
import { Button as ButtonType } from 'generated/graphql';
import Button from './Button';
import SuccessGraphic from './success.svg';

// By default, DetailedConfirmDialog will be a success dialog with title and graphic
const DetailedConfirmDialog = ({
  data,
  graphic = SuccessGraphic,
  title = 'Success',
  subtitle,
  actionButtons,
  onDialogClose,
}: {
  data: TableDialogFragment | undefined;
  graphic?: string;
  title?: string;
  subtitle?: string;
  actionButtons?: ButtonType[];
  onDialogClose: () => void;
}) => {
  const client = useClient();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [actionsLoading, setActionsLoading] = useState(false);

  const handleQuery = async (query: string) => {
    if (actionsLoading) return;
    setActionsLoading(true);
    const result = await client.mutation(query, undefined).toPromise();
    setActionsLoading(false);
    if (result.error?.message) {
      showSnackbar({ message: result.error.message }, SnackbarVariant.ERROR);
      return;
    }
  };

  const handleLink = (url: string) => {
    const target = getPrefixedRoute(url) as string;
    if (target.startsWith('http')) {
      window.location.href = target;
    } else {
      navigate(target.replace('#', ''), { replace: true });
    }
  };

  return (
    <Dialog open={!!data} onClose={() => onDialogClose()}>
      <DialogContent sx={(theme) => ({ padding: theme.spacing(2, 0) })}>
        <Box sx={{ maxWidth: '100%' }}>
          <img src={graphic} style={{ maxWidth: '100%' }} alt="" />
        </Box>
        <DialogTitle sx={(theme) => ({ textAlign: 'center', marginTop: theme.spacing(-2), padding: 0 })}>
          {title}
        </DialogTitle>
        {subtitle && (
          <Typography
            sx={(theme) => ({
              maxWidth: 270,
              margin: theme.spacing(1, 'auto'),
              textAlign: 'center',
            })}
            variant="body2"
            color="textSecondary"
          >
            {subtitle}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {actionButtons?.map((button, index) => {
            return (
              <Button
                key={index}
                type="submit"
                variant={(button.variant?.toLowerCase() as any) || 'contained'}
                color={(button.color?.toLowerCase() as any) || 'primary'}
                onClick={(event) => {
                  event.stopPropagation();
                  // For now, there should be at most 1 simple action for every action button in the confirm dialog to reduce complextiy unless requested by a project/design
                  const query = button.actions[0]?.query ?? '';
                  if (query.length > 0) {
                    handleQuery(query);
                  }
                  const url = button.actions[0]?.url ?? '';
                  if (url.length > 0) {
                    handleLink(url);
                  }
                  onDialogClose();
                }}
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                  width: `40%`,
                  display: 'flex',
                  justifyContent: 'center',
                })}
              >
                {button.value}
              </Button>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DetailedConfirmDialog;
