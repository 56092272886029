import { mapFieldValuesToId } from 'components/map-field-values-to-ids';
import { formatDateString, parseDateString } from 'loc/date';
import { Field, FieldGroup, FieldInput, FieldSet, Form, InputType } from 'generated/graphql';

export const buildUpdatedForm = (original: any, field: Field, value: string, values?: string[]): Form => ({
  ...original,
  fields: original.fields.map((f: Field) => {
    if (f.id === field.id) {
      return {
        ...f,
        value,
        values: values ?? [value].filter(Boolean),
      };
    }
    return f;
  }),
  fieldGroups: original.fieldGroups.map((group: FieldGroup) => ({
    ...group,
    fields: group.fields.map((f) => {
      if (f.id === field.id) {
        return {
          ...f,
          value,
          values: values ?? [value].filter(Boolean),
        };
      }
      return f;
    }),
  })),
  fieldSets: original.fieldSets.map((fieldSet: FieldSet) => ({
    ...fieldSet,
    groups: fieldSet.groups.map((group) => ({
      ...group,
      fields: group.fields.map((f) => {
        if (f.id === field.id) {
          return {
            ...f,
            value,
            values: values ?? [value].filter(Boolean),
          };
        }
        return f;
      }),
    })),
  })),
});

const parseField = (field: Field): Field => {
  if (field.type === InputType.Date) {
    return {
      ...field,
      value: parseDateString(field.value),
    };
  }

  return field;
};

const formatField = (field: Field): Field => {
  if (field.type === InputType.Date) {
    return {
      ...field,
      value: formatDateString(field.value),
    };
  }

  return field;
};

export const parseForm = (original: Form): Form => ({
  ...original,
  fields: original.fields.map((f) => ({
    ...f,
    value: parseField(f).value,
  })),
  fieldGroups: original.fieldGroups.map((group) => ({
    ...group,
    fields: group.fields.map((f) => ({
      ...f,
      value: parseField(f).value,
    })),
  })),
  fieldSets: original.fieldSets.map((fieldSet) => ({
    ...fieldSet,
    groups: fieldSet.groups.map((group) => ({
      ...group,
      fields: group.fields.map((f) => ({
        ...f,
        value: parseField(f).value,
      })),
    })),
  })),
});

export const showField = (field: Field, allFields: Field[]): boolean => {
  if (field.showIf?.fieldId) {
    const leadField = allFields.find((item) => item.id === field.showIf?.fieldId);
    if (!leadField) {
      return false;
    }

    if (!field.showIf.hasValues.includes(leadField.value)) {
      return false;
    }
  }

  return true;
};

export const showGroup = (group: FieldGroup) => {
  return group.cards.length > 0 || group.fields.some((field) => field.type !== InputType.Hidden);
};

export const showSet = (groups: FieldGroup[]) => {
  return groups.some((group) => showGroup(group));
};

export const getFormValues = async (formData: any): Promise<FieldInput[]> => {
  const mapField = async (field: Field) => {
    const formattedField = formatField(field);

    if (field.type === InputType.Multiselect) {
      return {
        id: field.id,
        value: formattedField.value,
        values: formattedField.values,
        entityID: field.entityID,
        fieldGroupID: field.fieldGroupID,
      };
    }

    if (field.type === InputType.Date) {
      return {
        id: field.id,
        value: formattedField.value,
        values: [formattedField.value],
        entityID: field.entityID,
        fieldGroupID: field.fieldGroupID,
      };
    }

    if (field.type === InputType.Calculated) {
      const { evaluate } = await import('services/math');
      const nameValueMap = mapFieldValuesToId(formData.fields);
      const expression = field.values.length > 0 ? field.values[0] : field.value;
      const result = evaluate(expression, nameValueMap);
      const value = Number.isNaN(result) ? '' : result.toString();
      return {
        id: field.id,
        value: value,
        values: [],
        entityID: field.entityID,
        fieldGroupID: field.fieldGroupID,
      };
    }

    return {
      id: field.id,
      value: formattedField.value,
      values:
        formattedField.values.length > 0 ? formattedField.values : formattedField.value.split(';').filter(Boolean),
      entityID: field.entityID,
      fieldGroupID: field.fieldGroupID,
    };
  };
  const fields = await Promise.all(formData.fields.map(mapField));
  const groupedFields: Awaited<ReturnType<typeof mapField>>[] = [];
  for (let group of formData.fieldGroups) {
    const fields = await Promise.all(group.fields.map(mapField));
    groupedFields.push(...fields);
  }

  const fieldSetFields: Awaited<ReturnType<typeof mapField>>[] = [];
  for (let fieldSet of formData.fieldSets) {
    for (let group of fieldSet.groups) {
      const filtered = group.fields.filter(
        (field: Field) => (field.value && field.value.length > 0) || field.values.length > 0,
      );
      const fields = await Promise.all(filtered.map(mapField));
      fieldSetFields.push(...fields);
    }
  }

  return [...fields, ...groupedFields, ...fieldSetFields];
};

export const replaceFormFields = (form: Form, fields: Field[]): Form => {
  const fieldDictionary = fields.reduce<{ [id: string]: Field }>(
    (acc, val) => ({
      ...acc,
      [val.id]: val,
    }),
    {},
  );
  return {
    ...form,
    fields: form.fields.map((field) => fieldDictionary[field.id] || field),
    fieldGroups: form.fieldGroups.map((group) => ({
      ...group,
      fields: group.fields.map((field) => fieldDictionary[field.id] || field),
    })),
    fieldSets: form.fieldSets.map((fieldSet) => ({
      ...fieldSet,
      groups: fieldSet.groups.map((group) => ({
        ...group,
        fields: group.fields.map((field) => fieldDictionary[field.id] || field),
      })),
    })),
  };
};
