import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { MdClose, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getPrefixedRoute } from 'services/theming';
import { useClient } from 'urql';
import { baseTheme } from 'config/base-theme';
import useIsViewport from 'hooks/useIsViewport';
import { SnackbarVariant, useSnackbar } from 'hooks/useSnackBar';
import downloadCSV from 'utils/download-csv';
import { Action, DialogSection as DialogSectionType, TableDialogFragment } from 'generated/graphql';
import ServerButton from './ServerButton';

export enum TableDialogVariant {
  Default = 'Default',
  Detailed = 'Detailed', // Detailed = dialog contains centered graphic, title, subtitle and action buttons
}

const SectionContent = ({
  section,
  loadingTrigger,
  onClose,
  onQuery,
}: {
  section: DialogSectionType;
  loadingTrigger: string | null;
  onClose: () => void;

  onQuery: (query: string) => void;
}) => {
  const { showSnackbar } = useSnackbar();
  return (
    <>
      <Box component="ul" sx={{ paddingX: 0, paddingY: 1 }}>
        {section.rows.map((row, j) => (
          <Box
            component="li"
            key={`${row.label + j}`}
            sx={{
              listStyleType: 'disc',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{row.label}:</span>
            <span>{row.value}</span>
          </Box>
        ))}
      </Box>
      {section.button && (
        <ServerButton
          button={section.button}
          loading={loadingTrigger === section.button.actions?.[0]?.query}
          onClick={() => {
            if (section.button?.actions.length === 1) {
              const action = section.button.actions[0];
              if (action.query === 'BACK') {
                onClose();
                return;
              }
              if (action.query.length > 0) onQuery(action.query);
              return;
            }

            if (section.button?.actions.length === 0) {
              onClose();
              return;
            }
            showSnackbar({ message: 'Cannot handle multiple actions on a button' }, SnackbarVariant.ERROR);
          }}
        />
      )}
    </>
  );
};

const CollapsableSection = ({ title, children }: { title: string; children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);
  return (
    <>
      <ListItemButton sx={{ paddingX: 0 }} onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

const NestedSection = ({
  section,
  loadingTrigger,
  onClose,
  onQuery,
}: {
  section?: DialogSectionType;
  loadingTrigger: string | null;
  onClose: () => void;
  onQuery: (query: string) => void;
}) => {
  if (!section) return null;
  return (
    <CollapsableSection title={section.title}>
      <SectionContent section={section} loadingTrigger={loadingTrigger} onClose={onClose} onQuery={onQuery} />
      {section.groups?.map((subSection, index) => {
        if (!subSection) return null;
        return (
          <NestedSection
            key={index}
            section={subSection}
            loadingTrigger={loadingTrigger}
            onClose={onClose}
            onQuery={onQuery}
          />
        );
      })}
    </CollapsableSection>
  );
};

const DialogSection = ({
  section,
  loadingTrigger,
  onClose,
  onQuery,
}: {
  section: DialogSectionType;
  loadingTrigger: string | null;
  onClose: () => void;
  onQuery: (query: string) => void;
}) => {
  return (
    <ListItem
      sx={{
        padding: 0,
        display: 'list-item',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      <ListItemText primary={section.title} />
      {section.rows.length > 0 && (
        <SectionContent loadingTrigger={loadingTrigger} section={section} onClose={onClose} onQuery={onQuery} />
      )}
      {section.groups?.map((subSection, index) => {
        if (!subSection) return null;
        return (
          <NestedSection
            key={index}
            section={subSection}
            loadingTrigger={loadingTrigger}
            onClose={onClose}
            onQuery={onQuery}
          />
        );
      })}
    </ListItem>
  );
};

const DialogContents = ({
  data,
  variant,
  graphic,
  onClose,
}: {
  data: TableDialogFragment;
  variant: TableDialogVariant;
  graphic?: ReactNode;
  onClose: (action?: Action) => void;
}) => {
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const client = useClient();
  const [loadingTrigger, setLoadingTrigger] = useState<string | null>(null);
  const isDetailed = variant === TableDialogVariant.Detailed;
  const showGraphic = isDetailed && graphic;

  const handleQuery = useCallback(
    async (query: string) => {
      setLoadingTrigger(query);
      const clientQueryMap = {
        mutation: async (query: any) => {
          return await client.mutation(query, undefined).toPromise();
        },
        query: async (query: any) => {
          return await client.query(query, undefined).toPromise();
        },
      };
      const result = query.startsWith('mutation')
        ? await clientQueryMap.mutation(query)
        : await clientQueryMap.query(query);
      setLoadingTrigger(null);
      if (result.error?.message) {
        showSnackbar({ message: result.error.message }, SnackbarVariant.ERROR);
        return;
      }
      if (result.data?.updateShipment?.hasToast) {
        showSnackbar(
          {
            message: result.data?.updateShipment?.toast.value,
            color: result.data?.updateShipment?.toast.color.toLowerCase(),
          },
          SnackbarVariant.ALERT,
        );
      }

      const redirectUrl = result.data?.updateShipment?.redirectUrl || result.data?.updateShipmentNew?.redirectUrl;

      if (redirectUrl) {
        navigate(getPrefixedRoute(redirectUrl), { replace: true });
      } else if (result.data?.bulkPaymentExport) {
        try {
          downloadCSV(result.data?.bulkPaymentExport, `payment_export_${Date.now()}`);
        } catch (error) {
          alert(`Failed to download file: ${error}`);
        }
      }
      onClose();
    },
    [client, navigate, onClose, showSnackbar],
  );

  return (
    <>
      {showGraphic && <Box sx={{ overflow: 'hidden' }}>{graphic}</Box>}
      <DialogTitle
        sx={(theme) => ({
          marginBottom: 0,
          textAlign: isDetailed ? 'center' : 'initial',
          paddingTop: isDetailed ? 0 : theme.spacing(2),
        })}
      >
        <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          {data.title}
          <IconButton
            onClick={() => {
              onClose();
            }}
            sx={{ paddingRight: 0 }}
          >
            <MdClose size={24} fill={baseTheme.palette.grey[700]} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={
          isDetailed
            ? {
                flex: 'none',
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  },
                },
              }
            : { maxWidth: '500px' }
        }
      >
        <Typography
          sx={(theme) => ({
            marginY: theme.spacing(1),
            textAlign: isDetailed ? 'center' : 'initial',
            color: isDetailed ? theme.palette.grey[700] : 'initial',
          })}
        >
          {data.info}
        </Typography>
        <List
          sx={(theme) => ({
            marginTop: theme.spacing(-2),
            color: theme.palette.text.primary,
          })}
        >
          {data.sections.map((section, i) => (
            <DialogSection
              section={section}
              loadingTrigger={loadingTrigger}
              key={`${section.title + i}`}
              onClose={onClose}
              onQuery={handleQuery}
            />
          ))}
        </List>
        <Stack direction="row" gap={1}>
          {data.buttons.map((button, index) => (
            <ServerButton
              key={index}
              button={button}
              loading={loadingTrigger === button.actions?.[0]?.query}
              sx={{ minWidth: `calc(100%/2 - 1%)` }}
              buttonSx={{ minWidth: '100%' }}
              onClick={() => {
                if (button.actions.length === 1) {
                  const action = button.actions[0];
                  if (action.query === 'BACK') {
                    onClose();
                    return;
                  }
                  if (action.query.length > 0) handleQuery(action.query);
                } else {
                  showSnackbar({ message: 'Cannot handle multiple actions on button' }, SnackbarVariant.ERROR);
                }
              }}
            />
          ))}
        </Stack>
      </DialogContent>
    </>
  );
};

const TableDialog = ({
  data,
  variant = TableDialogVariant.Default,
  graphic,
  onClose,
}: {
  data: TableDialogFragment | undefined;
  variant?: TableDialogVariant;
  graphic?: ReactNode;
  onClose: (action?: Action) => void;
}) => {
  const isMobile = useIsViewport();
  const isDetailed = variant === TableDialogVariant.Detailed;
  const dialogVariant = variant;

  const handleClose = () => onClose();
  return (
    <Dialog
      open={!!data}
      fullScreen={isDetailed && isMobile}
      onClose={handleClose}
      sx={
        isDetailed
          ? (theme) => ({
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  alignContent: 'center',
                  justifyContent: 'center',
                  [theme.breakpoints.down('md')]: {
                    borderRadius: 0,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: 500,
                  },
                },
              },
            })
          : {}
      }
    >
      {data && <DialogContents data={data} variant={dialogVariant} graphic={graphic} onClose={handleClose} />}
    </Dialog>
  );
};

export default TableDialog;
