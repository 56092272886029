/**
 * LineChartType Component
 *
 * A reusable line chart component built with Recharts, displaying time-series data.
 *
 * Data Format:
 * - Expects `validSeries: ValidSeries[]`, where each `ValidSeries` has:
 *   - `name`: string (series label, e.g., "Series A")
 *   - `data`: Array of { x: string | Date (time), y: number (value), ... }
 * - Transformed into: Flat array of data points with `x` as timestamps (ms) and `y` as values
 *
 * Features:
 * - X-Axis: Time-based (formatted as DD/MM/YYYY), with dynamic intervals (max 10 ticks)
 * - Y-Axis: Numerical values (formatted as K/M for thousands/millions)
 * - Supports multiple series with customizable colors and hidden series toggling
 * - Interactive: Tooltips (hover/click), legend with selection, and hover effects on dots
 * - Variants: LIGHT (colored lines) or DEFAULT (grey lines with secondary accents)
 * - Responsive design with adjustable height and debounced resizing
 */
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ResponsiveContainer, LineChart, Tooltip, Legend, CartesianGrid, XAxis, Label, YAxis, Line } from 'recharts';
import {
  ChartLight,
  ChartDefault,
  CHART_DEBOUNCE_TIME,
  ChartTooltipType,
  ChartVariant,
  ValidSeries,
} from './ChartFunctions';
import ChartTooltips from './ChartTooltips';
import CustomLegend from './CustomLegend';

interface LineChartTypeProps {
  height: number;
  validSeries: ValidSeries[];
  variant: ChartVariant;
  hiddenSeries: string[];
  onLegendSelect?: (e: any) => void;
}

const dateFormatter = (date: string) => {
  return dayjs(date).format('DD/MM/YYYY');
};

const MAX_DATA_POINTS = 10;

const LineChartType = ({
  height,
  validSeries,
  hiddenSeries,
  variant = ChartVariant.DEFAULT,
  onLegendSelect = () => {},
}: LineChartTypeProps) => {
  const theme = useTheme();
  const style = variant === ChartVariant.LIGHT ? ChartLight : ChartDefault;
  const [hoveredDot, setHoveredDot] = useState<string>();
  const [tooltipState, setTooltipState] = useState(true);

  const mappedData = validSeries
    .flatMap((s) => s.data)
    .map((data) => {
      data.x = new Date(data.x && data.x).getTime();
      return data;
    });

  if (mappedData.length === 0) {
    return null;
  }

  const interval = Math.round(mappedData.length / MAX_DATA_POINTS) - 1;

  return (
    <ResponsiveContainer height={height} width="99%" debounce={CHART_DEBOUNCE_TIME}>
      <LineChart
        data={mappedData}
        margin={{
          top: 10,
          right: 16,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip
          allowEscapeViewBox={{ x: false, y: true }}
          content={
            <ChartTooltips
              type={variant === ChartVariant.DEFAULT ? ChartTooltipType.Simple : ChartTooltipType.Drawer}
              open={tooltipState}
              onSetState={setTooltipState}
            />
          }
          wrapperStyle={{ zIndex: 9 }}
          trigger={variant === ChartVariant.DEFAULT ? 'hover' : 'click'}
        />
        {style.legend && (
          <Legend
            content={<CustomLegend dy={20} hiddenSeries={hiddenSeries} />}
            payload={validSeries.map((entry, index) => ({
              id: entry.name,
              value: entry.data[0].y,
              name: entry.name,
              color: style.colors[index % style.colors.length],
              type: 'line',
            }))}
            onClick={onLegendSelect}
          />
        )}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          allowDuplicatedCategory={false}
          tickFormatter={dateFormatter}
          axisLine={false}
          domain={[mappedData[0].x, mappedData[mappedData.length - 1].x]}
          tickLine={false}
          scale="time"
          type="number"
          interval={interval}
          angle={-90}
          textAnchor="end"
          height={90}
          tick={{ stroke: theme.palette.primary.dark, strokeWidth: style.strokeWidth }}
        >
          {/* @todo: get label value from data */}
          {variant === ChartVariant.LIGHT && <Label value="Period under review" dy={20} position="insideBottom" />}
        </XAxis>
        <YAxis
          dataKey="y"
          type="number"
          axisLine={false}
          tickLine={false}
          tick={{ stroke: theme.palette.primary.dark, strokeWidth: style.strokeWidth }}
          tickFormatter={(item) => {
            if (item === 0) return '';
            if (item >= 1000000) {
              return `${Number((item / 1000000).toFixed(1))}M`;
            }
            if (item >= 1000) {
              return `${(item / 1000).toFixed(0)}K`;
            }
            return `${item.toFixed(0)}`;
          }}
        >
          {/* @todo: get label value from data */}
          {variant === ChartVariant.LIGHT && (
            <Label value="Average net income (ZAR)" position="insideLeft" angle={-90} dy={75} />
          )}
        </YAxis>

        {validSeries.map((s, index) => (
          <Line
            hide={hiddenSeries.includes(s.name)}
            isAnimationActive={false}
            dataKey="y"
            data={s.data}
            name={s.name}
            key={index}
            type="linear"
            stroke={
              variant === ChartVariant.LIGHT ? style.colors[index % style.colors.length] : theme.palette.grey[300]
            }
            activeDot={{
              r: variant === ChartVariant.LIGHT ? 4 : 8,
              fill:
                variant === ChartVariant.LIGHT
                  ? style.colors[index % style.colors.length]
                  : theme.palette.secondary.main,
              strokeWidth: style.activeNodeStrokeWidth,
              onClick: () => setTooltipState(true),
            }}
            strokeWidth={style.lineStrokeWidth}
            strokeDasharray={style.strokeDashArray}
            dot={{
              fill:
                hoveredDot === s.name && variant === ChartVariant.LIGHT
                  ? style.colors[index % style.colors.length]
                  : 'white',
              stroke:
                variant === ChartVariant.LIGHT
                  ? style.colors[index % style.colors.length]
                  : theme.palette.secondary.main,
              strokeWidth: style.nodeStrokeWidth,
              r: 4,
              strokeDasharray: '',
              onMouseEnter: () => setHoveredDot(s.name),
              onMouseLeave: () => setHoveredDot(undefined),
            }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartType;
